import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderCaseRequestsApi = mCoderApi.injectEndpoints({
  tagTypes: ['CaseRequests'],
  endpoints: (builder) => ({
    getCaseRequests: builder.query({
      query: ({ skip, limit, clientID, geDate, leDate, forceRefetchToken }) => {
        let q = `/case_requests?skip=${skip}&limit=${limit}&forceRefetchToken=${forceRefetchToken}`;

        if (clientID) {
          q = `${q}&client_id=${clientID}`;
        }

        if (geDate) {
          q = `${q}&ge_date=${geDate}`;
        }

        if (leDate) {
          q = `${q}&le_date=${leDate}`;
        }

        return q;
      },
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.case_requests,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['CaseRequests', 'CaseDetail'],
    }),
    getCaseRequestCounts: builder.query({
      query: ({ geDate, leDate, forceRefetchToken }) => {
        let q = `/case_request_counts?forceRefetchToken=${forceRefetchToken}`;

        if (geDate) {
          q = `${q}&ge_date=${geDate}`;
        }

        if (leDate) {
          q = `${q}&le_date=${leDate}`;
        }

        return q;
      },
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['CaseRequests', 'Clients'],
    }),
    getCaseRequest: builder.query({
      query: ({ reqID }) => `/case_requests/${reqID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) =>
        response.case_requests && response.case_requests.data?.length > 0 ? response.case_requests.data[0] : null,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['CaseRequests', 'CaseDetail'],
    }),
    addCaseRequest: builder.mutation({
      query: (formData) => ({
        url: `/case_requests`,
        method: 'POST',
        body: formData,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      // User must explicitly refresh the page
      invalidatesTags: (result, error, arg) => ['Queues2', 'CaseRequests', 'Cases'],
    }),
    addCaseToRequest: builder.mutation({
      query: ({ formData, reqID }) => ({
        url: `/case_requests/${reqID}/cases`,
        method: 'POST',
        body: formData,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      // User must explicitly refresh the page
      invalidatesTags: (result, error, arg) => ['Queues2', 'CaseRequests', 'Cases'],
    }),
    retryCaseRequest: builder.mutation({
      query: ({ formData, reqID }) => ({
        url: `/case_requests/${reqID}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      // User must explicitly refresh the page
      invalidatesTags: (result, error, arg) => ['Queues2', 'CaseRequests', 'Cases'],
    }),
    completeCaseRequests: builder.query({
      query: ({ q }) => `/case_requests/complete?q=${q}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['CaseRequests'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCaseRequestCountsQuery,
  useGetCaseRequestsQuery,
  useGetCaseRequestQuery,
  useAddCaseRequestMutation,
  useAddCaseToRequestMutation,
  useRetryCaseRequestMutation,
  useCompleteCaseRequestsQuery,
} = mCoderCaseRequestsApi;
