import { useCallback } from 'react';
import { useMsal } from "@azure/msal-react";
// component
import { useSnackbar } from '../components/snackbar';

export default function useNotice({ variant }) {
  const { accounts } = useMsal();
  const { enqueueSnackbar } = useSnackbar();
  
  return useCallback(
    (msg) => {
      console.log('useNotice.msg_type=', typeof msg, 'useNotice.msg=', msg, 'accounts=', accounts, !accounts, accounts.length === 0);

      const noActiveAccount = !accounts || accounts.length === 0
      if (noActiveAccount) {
        // When the user is during logout, the portal may still try to call api, it may cause some errors.
        // However the user is logging out, we had better not to expose the error to user anymore.
        return;
      }

      if (typeof msg === 'string') {
        enqueueSnackbar(unescape(msg), { variant });
      } else if (typeof msg === 'object') {
        enqueueSnackbar(msg.message ?? JSON.stringify(msg), { variant });
      } else {
        enqueueSnackbar(JSON.stringify(msg), { variant });
      }
    },
    [enqueueSnackbar, variant, accounts]
  );
}

export function useErrorNotice() {
  return useNotice({ variant: 'error' });
}

export function useSuccessNotice() {
  return useNotice({ variant: 'success' });
}

export function useWarningNotice() {
  return useNotice({ variant: 'warning' });
}

export function useInfoNotice() {
  return useNotice({ variant: 'info' });
}
