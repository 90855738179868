import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
};

export default function RHFAutocomplete({ name, label, helperText, ...other }) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            setValue(name, newValue, { shouldValidate: true });
          }}
          // If we use Yup + react-hook-form with Autocomplete,
          // When we rerender this componenont, react-hook-form will reset the user input to AutoComplete inputValue
          // That will trigger a validate of Yup. If Yup think the input is invalid, react
          // will render the error message, which will trigger a new render round.
          // In new render round, react-hook-form will reset the user input to AutoComplete inputValue again
          // then it will cause Yup to validate it and render the error message again.
          // It is a infinity loop!
          //
          // As a simple fix, we just disable shouldValidate for the input change event. It will stop to validate the input value.
          onInputChange={(event, newInputValue) => {
            setValue(name, newInputValue, { shouldValidate: false });
          }}
          renderInput={(params) => (
            <TextField label={label} error={!!error} helperText={error ? error?.message : helperText} {...params} />
          )}
          {...other}
        />
      )}
    />
  );
}
