import { createSelector } from '@reduxjs/toolkit';

const selectUserEventTypes = createSelector(
  [(res) => res?.data?.user_event_types],
  (getUserEventTypes) => getUserEventTypes ?? {}
);

export const selectAllUserEventTypes = () =>
  createSelector([selectUserEventTypes], (allUserEventTypes) =>
    allUserEventTypes ? Object.entries(allUserEventTypes) : []
  );

const selectStatus = createSelector([(res) => res?.data?.status], (getStatus) => getStatus ?? {});

export const selectStatusList = () =>
  createSelector([selectStatus], (statusList) => (statusList ? Object.entries(statusList) : []));

export const selectStatusText = () =>
  createSelector([selectStatus, (state, status) => status], (allStatus, status) =>
    allStatus ? allStatus[status] : null
  );

const selectEventTypes = createSelector([(res) => res?.data?.event_types], (getEventTypes) => getEventTypes ?? {});

export const selectAllEventTypes = () =>
  createSelector([selectEventTypes], (allEventTypes) => (allEventTypes ? Object.entries(allEventTypes) : []));

export const selectEventTypeText = () =>
  createSelector([selectEventTypes, (state, eventType) => eventType], (allEventTypes, eventType) =>
    allEventTypes ? allEventTypes[eventType] : null
  );

export const selectSystemAccounts = () =>
  createSelector([(res) => res?.data?.system_accounts], (getSystemAccounts) => getSystemAccounts ?? {});

export const selectNoAssignedFilters = () =>
  createSelector([(res) => res?.data?.no_assigned_filters], (getNoAssignedFilters) => getNoAssignedFilters ?? []);

export const selectAllAssignedFilters = () =>
  createSelector([(res) => res?.data?.all_assigned_filters], (getAllAssignedFilters) => getAllAssignedFilters ?? []);

const selectSearchFilters = createSelector(
  [(res) => res?.data?.search_filters],
  (getSearchFilters) => getSearchFilters ?? {}
);

export const selectExactMatchOptions = () =>
  createSelector([selectSearchFilters], (allSettings) => allSettings.exact_match ?? []);

export const selectTagFilterOptions = () =>
  createSelector([selectSearchFilters], (allSettings) => allSettings.tag_filters ?? []);

export const selectAttrFiltersOptions = () =>
  createSelector([selectSearchFilters], (allSettings) => allSettings.attr_filters ?? []);

export const selectDateFilterOptions = () =>
  createSelector([selectSearchFilters], (allSettings) => allSettings.date_filters ?? []);

export const selectSortOptions = () =>
  createSelector([selectSearchFilters], (allSettings) => allSettings.sort_options ?? []);
