import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// hooks
import { useDialog } from '../hooks';
// api
import { useLazyMeQuery } from '../features/apis/mCoderApi';
// dialog
import { CountdownDialog } from './dialogs';

// Every 5 minutes we check with backend to see if user need a force logout
const API_CHECK_FREQ = 5 * 60 * 1000;
// Automatically count down after some seconds
const COUNTDOWN_IN_SEC = 15;

ForceLogout.propTypes = {
  onLogout: PropTypes.func,
};

export function ForceLogout({ onLogout: handleLogout = () => {} }) {
  const [started, setStarted] = useState(false);
  const [openDialog, dialog] = useDialog({
    createDialog: ({ opened, handleClose }) => (
      <CountdownDialog
        description={`Per administrator requested, you must logout now. Please click the button to logout, or you will be logout in ${COUNTDOWN_IN_SEC} seconds.`}
        started={started}
        countdownInMs={COUNTDOWN_IN_SEC}
        open={opened}
        onClose={handleClose}
        onExpire={handleLogout}
      />
    ),
  });

  const [triggerMeQuery] = useLazyMeQuery();
  const checkForceLogoutRequest = useCallback(() => {
    triggerMeQuery()
      .unwrap()
      .then(async (resp) => {
        if (resp.logout_requested) {
          openDialog();
          setStarted((oldValue) => true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // triggerMeQuery will be changed periodically, we shouldn't put it as dependences
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  useEffect(() => {
    // Start checking the API result periodically
    const interval = setInterval(checkForceLogoutRequest, API_CHECK_FREQ); // Adjust the interval as needed

    return () => {
      // Clean up the interval when the component is unmounted
      clearInterval(interval);
    };
  }, [checkForceLogoutRequest]);

  return <>{dialog}</>;
}
