import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderEventsApi = mCoderApi.injectEndpoints({
  tagTypes: ['Events'],
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: ({ caseID }) => `/cases/${caseID}/events`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.events,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'Events', id: arg.caseID }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetEventsQuery } = mCoderEventsApi;
