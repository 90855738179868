import { createSelector } from '@reduxjs/toolkit';

export const selectAllConfigs = createSelector([(res) => res?.data], (configs) => configs ?? {});

export const selectMaxUploadSizeConfig = () =>
  createSelector([selectAllConfigs], (configs) => configs?.MAX_UPLOAD_SIZE ?? {});

export const selectEnableDelayCloseConfig = () =>
  createSelector([selectAllConfigs], (configs) => configs?.ENABLE_DELAY_CLOSE ?? {});

export const selectDelayCloseHoursConfig = () =>
  createSelector([selectAllConfigs], (configs) => configs?.DELAY_CLOSE_HOURS ?? {});

export const selectEnableAutoUnassignConfig = () =>
  createSelector([selectAllConfigs], (configs) => configs?.ENABLE_AUTO_UNASSIGN ?? {});

export const selectEnableCoderAssignConfig = () =>
  createSelector([selectAllConfigs], (configs) => configs?.ENABLE_CODER_ASSIGN ?? {});

export const selectEnableReopenCaseConfig = () =>
  createSelector([selectAllConfigs], (configs) => configs?.ENABLE_REOPEN_CASE ?? {});

export const selectUXPageSizeConfig = () =>
  createSelector([selectAllConfigs], (configs) => configs?.UX_PAGE_SIZE ?? {});
