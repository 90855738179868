import { createSelector, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const mapLetterToBase26Char = (letter) => {
  const charCodeOfA = 'A'.charCodeAt(0);
  const charCodeOfJ = 'J'.charCodeAt(0);
  const charCodeOf1 = '1'.charCodeAt(0);
  const charCode = letter.charCodeAt(0);
  // 'A' -> '1', ..., 'I' -> '9'
  // 'J' -> 'A', ..., 'Z' -> 'P'
  return String.fromCharCode(
    charCode < charCodeOfJ ? charCode - charCodeOfA + charCodeOf1 : charCode - charCodeOfJ + charCodeOfA
  );
};

const mapBase26CharToLetter = (base26Char) => {
  const charCodeOfA = 'A'.charCodeAt(0);
  const charCodeOfJ = 'J'.charCodeAt(0);
  const charCodeOf1 = '1'.charCodeAt(0);
  const charCodeOf9 = '9'.charCodeAt(0);
  const charCode = base26Char.charCodeAt(0);
  return String.fromCharCode(
    charCode <= charCodeOf9 ? charCode - charCodeOf1 + charCodeOfA : charCode - charCodeOfA + charCodeOfJ
  );
};

// 4 Ways to Convert String to Character Array in JavaScript
// https://www.samanthaming.com/tidbits/83-4-ways-to-convert-string-to-character-array/
export const mapStringToBase26String = (s) => Array.from(s).map(mapLetterToBase26Char).join('');
export const mapBase26StringToString = (s) => Array.from(s).map(mapBase26CharToLetter).join('');

// base conversion
// https://medium.com/@krishnaregmi/base-conversion-in-javascript-5ab6c6ad0b04
export const mapBase26StringToBase10Number = (s) => parseInt(s, 26);
export const mapBase10NumberToBase26String = (s) => s.toString(26);

export const getNewPointerID = (pointerIDs) => {
  // pointerIDs = ['A', 'C', 'AB', 'B', 'AA']
  // =>
  // ['A', 'B', 'C', 'AA', 'AB']

  // js sort is by utf-8 string. For sorting by integer correctly, please read: https://stackoverflow.com/questions/1063007/how-to-sort-an-array-of-integers-correctly
  const base10PointerIDs = pointerIDs
    .map(mapStringToBase26String)
    .map(mapBase26StringToBase10Number)
    .sort((a, b) => a - b);
  // [ 1, 2, 3, 27, 28 ] -> 28 -> 29
  const newBase10PointerID = base10PointerIDs.length > 0 ? base10PointerIDs[base10PointerIDs.length - 1] + 1 : 1;
  return getNewPointerIDFromNumber(newBase10PointerID);
};

export const getNewPointerIDFromNumber = (n) => {
  // https://medium.com/@krishnaregmi/base-conversion-in-javascript-5ab6c6ad0b04
  // 29 -> '13'
  const newBase26PointerID = mapBase10NumberToBase26String(n);
  // '13' -> 'AC'
  return mapBase26StringToString(newBase26PointerID);
};

export const NO_CHARGE_CHARGES = [
  {
    cpt_code: {
      ver: '4',
      value: 'NOCHARGE',
      description: '',
      supporting_reasons: null,
    },
    quantity: 1,
    modifiers: [],
    diagnosis_pointers: ['A'],
  },
];

export const NO_CHARGE_DIAGNOSES = [
  {
    icd_code: {
      ver: '10',
      value: 'Z02.9',
      description: 'Encounter for administrative examinations, unspecified',
    },
    pointer_id: 'A',
  },
];

const codingResultEquals = (x, y) => _.isEqual(x, y);

const isCodingResultChanged = (x, y) => !codingResultEquals(x, y);

export const caseCodingResultSlice = createSlice({
  name: 'caseCodingResult',
  initialState: {
    /*
          "charges": [
            {
              "cpt_code": {
                "ver": "4",
                "value": "93979",
                "description": "DUP-SCAN AORTA IVC ILIAC VASCL/BPGS UNI/LMTD",
                "score": 0.9900898337364197
              },
              "dos": "2022-07-08T00:00:00",
              "quantity": 1,
              "modifiers": [
                "26"
              ],
              "diagnosis_pointers": [
                "A"
              ]
            }
          ],
    */
    charges: [],
    // This will be used to compare with the changes and decide the dirty flag
    initialCharges: [],
    /*
          "diagnoses": [
            {
              "icd_code": {
                "ver": "10",
                "value": "I739",
                "description": "Peripheral vascular disease, unspecified",
                "supporting_reasons": [
                  {
                    "text": "Peripheral vascular disease, unspecified.",
                    "kind": "INDICATION",
                    "score": 0.9854602831053256
                  },
                  {
                    "text": "Elevated peak systolic velocities within both common iliac arteries, right greater than left,\nsuggestive of bilateral common iliac arterial stenosis.",
                    "kind": "IMPRESSION",
                    "score": 0.7872140661222744
                  },
                  {
                    "text": "Bilateral iliac stents.",
                    "kind": "INDICATION",
                    "score": 0.7840873856440211
                  }
                ],
                "score": 0.9884846806526184
              },
              "pointer_id": "A"
            }
          ],
     */
    diagnoses: [],
    // This will be used to compare with the changes and decide the dirty flag
    initialDiagnoses: [],
    dirty: false,
    columnEditing: true,
  },
  reducers: {
    codingResultReset: (draftState, action) => {
      draftState.charges = draftState.initialCharges;
      draftState.diagnoses = draftState.initialDiagnoses;
      draftState.dirty = false;
    },
    setNoCharge: (draftState, action) => {
      draftState.charges = JSON.parse(JSON.stringify(NO_CHARGE_CHARGES));
      draftState.diagnoses = JSON.parse(JSON.stringify(NO_CHARGE_DIAGNOSES));
      draftState.dirty = true;
    },
    initManualCodingResults: (draftState, action) => {
      const { charges, diagnoses } = action.payload;
      const formattedCharges = charges ? JSON.parse(JSON.stringify(charges)) : [];
      const formattedDiagnoses = diagnoses ? JSON.parse(JSON.stringify(diagnoses)) : [];
      draftState.charges = formattedCharges;
      draftState.diagnoses = formattedDiagnoses;
      // This reducer will be called when the coding result is loaded from the server.
      // We will use the server data to initialize the initialCharges and initialDiagnoses.
      draftState.initialCharges = formattedCharges;
      draftState.initialDiagnoses = formattedDiagnoses;
      draftState.dirty = false;
    },
    setCharges: (draftState, action) => {
      draftState.charges = action.payload ? JSON.parse(JSON.stringify(action.payload)) : [];
      // draftState.dirty = true;
      // We test the equality of the initialDiagnoses and diagnoses to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    turnOnColumnEditing: (draftState, action) => {
      draftState.columnEditing = true;
    },
    turnOffColumnEditing: (draftState, action) => {
      draftState.columnEditing = false;
    },
    setDiagnoses: (draftState, action) => {
      draftState.diagnoses = action.payload ? JSON.parse(JSON.stringify(action.payload)) : [];
      // draftState.dirty = true;
      // We test the equality of the initialDiagnoses and diagnoses to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.diagnoses, draftState.initialDiagnoses);
    },
    // cptCodes
    cptCodeAdded: (draftState, action) => {
      const {
        cpt_code: { ver, value, description },
        diagnosis_pointers,
      } = action.payload;
      const exists = draftState.charges.find((charge) => charge?.icd_code?.value === value);
      if (exists === undefined || exists === null) {
        draftState.charges.push({
          cpt_code: {
            ver: ver ?? '4',
            value,
            description,
          },
          quantity: 1,
          modifiers: [],
          diagnosis_pointers: diagnosis_pointers ?? [],
        });
        // draftState.dirty = true;
        // We test the equality of the initialCharges and charges to decide the dirty flag.
        draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
      }
    },
    cptCodeDeleted: (draftState, action) => {
      const { index } = action.payload;
      draftState.charges = draftState.charges.filter((val, i) => i !== index);
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeMove: (draftState, action) => {
      const { oldIndex, newIndex } = action.payload;
      const charges = Array.from(draftState.charges);
      const [removed] = charges.splice(oldIndex, 1);
      charges.splice(newIndex, 0, removed);
      draftState.charges = charges;
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeDiagnosisPointerAdded: (draftState, action) => {
      const { index, diagnosis_pointer } = action.payload;
      draftState.charges[index].diagnosis_pointers.push(diagnosis_pointer);
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeDiagnosisPointersUpdated: (draftState, action) => {
      const { index, diagnosis_pointers } = action.payload;
      draftState.charges[index].diagnosis_pointers = diagnosis_pointers;
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeDiagnosisPointerDeleted: (draftState, action) => {
      const { chargeIndex, diagnosis_pointer } = action.payload;
      const { diagnosis_pointers } = draftState.charges[chargeIndex];
      draftState.charges[chargeIndex].diagnosis_pointers = diagnosis_pointers.filter(
        (val) => val !== diagnosis_pointer
      );
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeDiagnosisPointerMoved: (draftState, action) => {
      const { chargeIndex, oldPointerIndex, newPointerIndex } = action.payload;
      const { diagnosis_pointers } = draftState.charges[chargeIndex];

      // move
      const cloned_diagnosis_pointers = Array.from(diagnosis_pointers);
      const [removed] = cloned_diagnosis_pointers.splice(oldPointerIndex, 1);
      cloned_diagnosis_pointers.splice(newPointerIndex, 0, removed);

      draftState.charges[chargeIndex].diagnosis_pointers = cloned_diagnosis_pointers;
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeModifierAdded: (draftState, action) => {
      const { index, modifier } = action.payload;
      draftState.charges[index].modifiers.push(modifier);
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeModifierDeleted: (draftState, action) => {
      const { chargeIndex: index, modifierIndex } = action.payload;
      draftState.charges[index].modifiers = draftState.charges[index].modifiers.filter((val, i) => i !== modifierIndex);
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeCommentUpdated: (draftState, action) => {
      const { index, comment } = action.payload;
      draftState.charges[index].comment = comment;
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeQuantityUpdated: (draftState, action) => {
      const { index, quantity } = action.payload;
      draftState.charges[index].quantity = quantity;
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeQuantityIncremented: (draftState, action) => {
      const { index } = action.payload;
      const oldQuantity = draftState.charges[index].quantity;
      draftState.charges[index].quantity = oldQuantity + 1;
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    cptCodeQuantityDecremented: (draftState, action) => {
      const { index } = action.payload;
      const oldQuantity = draftState.charges[index].quantity;
      draftState.charges[index].quantity = Math.max(oldQuantity - 1, 1);
      // draftState.dirty = true;
      // We test the equality of the initialCharges and charges to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.charges, draftState.initialCharges);
    },
    icdCodeAdded: (draftState, action) => {
      const { ver, value, description } = action.payload;
      const exists = draftState.diagnoses.find((diagnose) => diagnose.icd_code.value === value);
      if (exists === undefined || exists === null) {
        draftState.diagnoses.push({
          icd_code: {
            ver,
            value,
            description,
          },
          pointer_id: getNewPointerID(draftState.diagnoses.map((el) => el.pointer_id)),
        });
        // draftState.dirty = true;
        // We test the equality of the initialDiagnoses and diagnoses to decide the dirty flag.
        draftState.dirty = isCodingResultChanged(draftState.diagnoses, draftState.initialDiagnoses);
      }
    },
    icdCodeDeleted: (draftState, action) => {
      const { index } = action.payload;
      draftState.diagnoses = draftState.diagnoses.filter((val, i) => i !== index);
      // draftState.dirty = true;
      // We test the equality of the initialDiagnoses and diagnoses to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.diagnoses, draftState.initialDiagnoses);
    },
    icdCodeMove: (draftState, action) => {
      const { oldIndex, newIndex } = action.payload;
      const diagnoses = Array.from(draftState.diagnoses);
      const [removed] = diagnoses.splice(oldIndex, 1);
      diagnoses.splice(newIndex, 0, removed);
      draftState.diagnoses = diagnoses;
      // draftState.dirty = true;
      // We test the equality of the initialDiagnoses and diagnoses to decide the dirty flag.
      draftState.dirty = isCodingResultChanged(draftState.diagnoses, draftState.initialDiagnoses);
    },
    dirtyFlagReset: (draftState, action) => {
      draftState.dirty = false;
    },
  },
});

export const {
  codingResultReset,
  initManualCodingResults,
  setNoCharge,
  setCharges,
  setDiagnoses,
  cptCodeAdded,
  cptCodeDeleted,
  cptCodeMove,
  cptCodeDiagnosisPointerAdded,
  cptCodeDiagnosisPointersUpdated,
  cptCodeDiagnosisPointerDeleted,
  cptCodeDiagnosisPointerMoved,
  cptCodeModifierAdded,
  cptCodeModifierDeleted,
  cptCodeCommentUpdated,
  cptCodeQuantityUpdated,
  cptCodeQuantityIncremented,
  cptCodeQuantityDecremented,
  icdCodeAdded,
  icdCodeDeleted,
  icdCodeMove,
  dirtyFlagReset,
  turnOnColumnEditing,
  turnOffColumnEditing,
} = caseCodingResultSlice.actions;

export default caseCodingResultSlice.reducer;

export const selectCharges = (state) => state.caseCodingResult.charges;

export const formatICDCode = (s) => (s && s.length > 3 && s[3] !== '.' ? `${s.substring(0, 3)}.${s.substring(3)}` : s);

const selectDiagnoses_ = (state) => state.caseCodingResult.diagnoses;
export const selectDiagnoses = createSelector(selectDiagnoses_, (charges) =>
  charges.map((d) => ({
    ...d,
    icd_code: {
      ...d.icd_code,
      value: formatICDCode(d.icd_code?.value),
    },
  }))
);

export const selectDirtyFlag = (state) => state.caseCodingResult.dirty;

export const selectDiagnosisPointers = (chargeIndex) => (state) => {
  const { charges } = state.caseCodingResult;
  return charges && charges.length > chargeIndex ? charges[chargeIndex].diagnosis_pointers : null;
};

export const selectColumnEditing = (state) => state.caseCodingResult.columnEditing;

export const areDXsSame = (charges) => {
  if (!charges) {
    return true;
  }

  if (charges.length === 0) {
    // no need to sync DXs at all
    return false;
  }

  if (charges.length === 1) {
    return true;
  }

  const arrayToString = (charge) => (charge.diagnosis_pointers ?? []).join(',');
  const firstDXs = arrayToString(charges[0]);
  return charges.every((charge) => arrayToString(charge) === firstDXs);
};
