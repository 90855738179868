import { useMemo, useEffect } from 'react';
// api
import { useGetUsersQuery } from '../../apis/mCoderApi';
// selector
import { selectUsers, selectUserByID, selectUsersByRole } from '../usersSelectors';
// hooks
import { useErrorNotice } from '../../../hooks';
import { useSystemAccounts } from '../../settings/hooks';

export function useGetUsers(query, opts) {
  const getUsers = useGetUsersQuery(query, opts);
  const noticeError = useErrorNotice();
  useEffect(() => {
    if (getUsers.isError) {
      noticeError(getUsers.error);
    }
  }, [noticeError, getUsers]);
  return getUsers;
}

export function useUsers(include_invited_user, opts = undefined) {
  const getUsers = useGetUsers({ include_invited_user }, opts);
  return [getUsers.data ?? [], getUsers];
}

export function useFilteredUsers(include_invited_user, filter) {
  const [systemAccounts] = useSystemAccounts();

  // useMemo pattern
  // https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
  const cachedSelectUsers = useMemo(selectUsers, []);
  const getUsers = useGetUsers(
    { include_invited_user },
    {
      selectFromResult: (result) => ({
        // We can optionally include the other metadata fields from the result here
        ...result,
        allUsers: cachedSelectUsers(result, (user) => !Object.values(systemAccounts).includes(user.id) && filter(user)),
      }),
    }
  );

  return [getUsers.allUsers ?? [], getUsers];
}

export function useUsersByRole(include_invited_user, role) {
  // useMemo pattern
  // https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
  const cachedSelectUsersByRole = useMemo(selectUsersByRole, []);
  const getUsers = useGetUsers(
    { include_invited_user },
    {
      selectFromResult: (result) => ({
        // We can optionally include the other metadata fields from the result here
        ...result,
        filteredUsers: cachedSelectUsersByRole(result, role),
      }),
    }
  );

  return [getUsers.filteredUsers ?? [], getUsers];
}

export function useUser(userID) {
  // useMemo pattern
  // https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
  const cachedSelectUserByID = useMemo(selectUserByID, []);

  const getUsers = useGetUsers(
    { include_invited_user: false },
    {
      selectFromResult: (result) => ({
        // We can optionally include the other metadata fields from the result here
        ...result,
        // Include a field called `user` in the hook result object,
        // which will be a specific user
        user: cachedSelectUserByID(result, userID),
      }),
    }
  );

  return [getUsers.user, getUsers];
}

export function useUserName(userID) {
  const [systemAccounts] = useSystemAccounts();

  const [user, getUsers] = useUser(userID);
  if (user) {
    return [user?.name, getUsers];
  }

  // It may be a system account
  const entry = Object.entries(systemAccounts).find(([name, id]) => id === userID);
  const sysAcctName = entry ? entry[0] : null; // Check if entry is not undefined before destructuring
  return [sysAcctName, getUsers];
}

export function useIsRole(userID, role) {
  const [user, getUsers] = useUser(userID);
  return [user?.roles.includes(role), getUsers];
}
