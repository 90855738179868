import PropTypes from 'prop-types';
import { createContext, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
//
import { loginRequest } from './MsalConfig';
import { authInitialized, signedIn, signedOut, selectAuth } from '../features/auth/authSlice';
// api
import { useAuthorizedMutation, useLazyMeQuery, useLogoutMutation } from '../features/apis/mCoderApi';
// hook
import { useCachedDispatch } from '../hooks';

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const authState = useSelector(selectAuth);
  const cachedDispatch = useCachedDispatch();

  const [authorized] = useAuthorizedMutation();
  const [logout] = useLogoutMutation();
  const [triggerMeQuery] = useLazyMeQuery();

  const { instance } = useMsal();

  useEffect(() => {
    // This will be run on component mount
    const callbackId = instance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS: {
          // Here is a design:
          // We must call authorized api after the user sign in Microsoft account successfully for 2 reasons:
          // 1. Use authorized api to verify the user has a permission on mCoder api. If the user has no permission, then we should allow him sign in.
          // 2. We have SPA (React UI) and API (mCoder API) two Azure apps. But we don't want to maintain two permission sets. The trick is we fetch API permission
          // by calling 'authorized' api, and use mCoder API permisson as SPA permission.
          authorized()
            .unwrap()
            .then((resp) => {
              cachedDispatch(
                signedIn({
                  user: message.payload.account,
                  roles: resp.roles ?? [],
                })
              );
            })
            .catch((error) => {
              console.log(error);
            });
          break;
        }
        case EventType.LOGIN_FAILURE:
          // Just output the failure, and then do nothing. In theory, user should be still in login page.
          console.log(`Login Failure ${message}`);
          break;
        case EventType.LOGOUT_START:
          break;
        case EventType.LOGOUT_FAILURE:
          break;
        case EventType.LOGOUT_SUCCESS:
          cachedDispatch(signedOut());
          break;
        case EventType.LOGOUT_END:
          break;
        case EventType.ACCOUNT_ADDED:
          break;
        case EventType.ACCOUNT_REMOVED:
          break;
        case EventType.ACQUIRE_TOKEN_SUCCESS:
          break;
        default:
          break;
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, cachedDispatch, authorized]);

  const initialize = useCallback(async () => {
    const accounts = await instance.getAllAccounts();
    if (accounts.length === 0) {
      cachedDispatch(
        authInitialized({
          isAuthenticated: false,
          user: null,
          roles: [],
        })
      );
    } else {
      // When reinitialize, we also use `me` api to verify the roles
      triggerMeQuery()
        .unwrap()
        .then(async (resp) => {
          cachedDispatch(
            authInitialized({
              isAuthenticated: true,
              user: accounts[0],
              roles: resp.roles,
            })
          );
        })
        .catch((error) => {
          console.log('triggerMeQuery is getting an error', error);
        });
    }
  }, [instance, cachedDispatch, triggerMeQuery]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const cachedLogin = useCallback(() => {
    instance.loginRedirect(loginRequest);
  }, [instance]);

  // LOGOUT
  const cachedLogout = useCallback(() => {
    logout()
      .unwrap()
      .then((resp) => {
        // instance.logoutRedirect will destroy the token, we must call it after we notify api with token.
        instance.logoutRedirect();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [instance, logout]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: authState.isInitialized,
      isAuthenticated: authState.isAuthenticated,
      method: 'msal',
      login: cachedLogin,
      logout: cachedLogout,
    }),
    [authState.isAuthenticated, authState.isInitialized, cachedLogin, cachedLogout]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
