import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderCommentApi = mCoderApi.injectEndpoints({
  tagTypes: ['Comments'],
  endpoints: (builder) => ({
    getComments: builder.query({
      query: ({ caseID }) => `/cases/${caseID}/comments`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.comments,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'Comments', id: arg.caseID }],
    }),
    postComment: builder.mutation({
      // userID is only for generating FetchLogs invalidate signal
      query: ({ userID, caseID, comment, comment_to = null, changeFetchLog = false }) => ({
        url: `/cases/${caseID}/comments`,
        method: 'POST',
        body: {
          comment,
          comment_to,
          change_fetch_log: changeFetchLog
        },
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        { type: 'Comments', id: arg.caseID },
        { type: 'Events', id: arg.caseID },
        { type: 'FetchLogs', id: `${arg.userID}_${arg.caseID}` }
      ],
    }),
  }),
  overrideExisting: true,
});

export const { useGetCommentsQuery, usePostCommentMutation } = mCoderCommentApi;
