import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderCompleteApi = mCoderApi.injectEndpoints({
  endpoints: (builder) => ({
    completeICDCodes: builder.query({
      query: ({ q }) => `/complete/icd?q=${q}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
    completeCPTCodes: builder.query({
      query: ({ q }) => `/complete/cpt?q=${q}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
    completeModifiers: builder.query({
      query: ({ q }) => `/complete/modifier?q=${q}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
  }),
  overrideExisting: true,
});

export const {
  useCompleteICDCodesQuery,
  useLazyCompleteICDCodesQuery,
  useCompleteCPTCodesQuery,
  useLazyCompleteCPTCodesQuery,
  useCompleteModifiersQuery,
  useLazyCompleteModifiersQuery,
} = mCoderCompleteApi;
