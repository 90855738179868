export const ENV = process.env.REACT_APP_ENV;
console.debug('ENV=', ENV);

export const VER = process.env.REACT_APP_VER;
console.debug('VER=', VER);

// mCoder API
// ----------------------------------------------------------------------
export const ROLE_ADMIN = 'mCoder.Admin';
export const ROLE_CODER_MANAGER = 'mCoder.CoderManager';
export const ROLE_AUDITOR = 'mCoder.Auditor';
export const ROLE_CODER = 'mCoder.Coder';
export const ROLE_MEMBER = 'mCoder.Member';
export const ROLE_PROVIDER = 'mCoder.Provider';
export const ROLE_COORDINATOR = 'mCoder.Coordinator';
export const MCODER_API = {
  baseUrl: process.env.REACT_APP_MCODER_API_BASEURL ?? '',
  roles: {
    displayMapping: {
      [ROLE_ADMIN]: 'Administrator',
      [ROLE_CODER_MANAGER]: 'Coder Manager',
      [ROLE_AUDITOR]: 'Auditor',
      [ROLE_CODER]: 'Coder',
      [ROLE_PROVIDER]: 'Provider',
      [ROLE_COORDINATOR]: 'Coordinator',
      // Must put ROLE_MEMBER as last
      [ROLE_MEMBER]: 'User',
    },
  },
};
// console.debug('MCODER_API=', MCODER_API);

export const AZURE = {
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
  mCoderApiScopes: [process.env.REACT_APP_MCODER_API_SCOPE],
};
// console.debug('AZURE=', AZURE);

export const ACTION_PROVIDER_PREREVIEW = 'Triage';
export const ACTION_PROVIDER_REVIEW = 'Clarify';
export const ACTION_PROVIDER_REVIEWED = 'Follow Up';
export const ACTION_PROVIDER_HOLD = 'Check';
export const ACTION_PROVIDER_READONLY = 'Check';

export const MENU_TEXT__COORDINATOR = 'Coordinator';
export const MENU_TEXT__PROVIDER = 'Provider';

// Sentry
// ----------------------------------------------------------------------
export const SENTRY_SETTINGS = {
  dsn: process.env.REACT_APP_SENTRY_DSN ?? '',
  tracePropagationTargets: ['localhost', /^\//, /minset.ai/],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE)
    : 0.0,
  replaysSessionSampleRate: process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE)
    : 0.1,
  replaysOnErrorSampleRate: 1.0,
};
console.debug('SENTRY_SETTINGS=', SENTRY_SETTINGS);

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_LARGE: 320,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
