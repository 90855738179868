import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderWebhooksApi = mCoderApi.injectEndpoints({
  tagTypes: ['Webhooks', 'WebhookLogs'],
  endpoints: (builder) => ({
    getWebhooks: builder.query({
      query: () => `/webhooks`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.webhooks,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Webhooks'],
    }),
    getWebhook: builder.query({
      query: ({ webhookID }) => `/webhooks/${webhookID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) =>
        response.webhooks && response.webhooks.length > 0 ? response.webhooks[0] : null,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Webhooks'],
    }),
    getTestData: builder.query({
      query: () => `/webhooks/test_data`,
      transformErrorResponse: customTransformErrorResponse,
    }),
    createWebhook: builder.mutation({
      query: ({ name, url }) => ({
        url: `/webhooks`,
        method: 'POST',
        body: {
          name,
          url,
        },
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Webhooks'],
    }),
    deleteWebhook: builder.mutation({
      query: ({ webhookID }) => ({
        url: `/webhooks/${webhookID}`,
        method: 'DELETE',
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Webhooks'],
    }),
    updateWebhook: builder.mutation({
      query: ({ webhookID, name, url, headers, body }) => ({
        url: `/webhooks/${webhookID}`,
        method: 'POST',
        body: {
          name,
          url,
          headers,
          body,
        },
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Webhooks'],
    }),
    activateWebhook: builder.mutation({
      query: ({ webhookID }) => ({
        url: `/webhooks/${webhookID}/activate`,
        method: 'POST',
      }),
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Webhooks'],
    }),
    testWebhook: builder.mutation({
      query: ({ webhookID, name, url, headers, body }) => ({
        url: `/webhooks/${webhookID}/test`,
        method: 'POST',
        body: {
          name,
          url,
          headers,
          body,
        },
      }),
      transformErrorResponse: customTransformErrorResponse,
    }),
    getWebhookLogs: builder.query({
      query: ({ webhookID, caseID = null, httpStatusCode = null, skip, limit, forceRefetchToken = null }) => {
        let url = `/webhooks/${webhookID}/logs?skip=${skip}&limit=${limit}`;

        if (caseID) {
          url = `${url}&case_id=${caseID}`;
        }

        if (httpStatusCode) {
          url = `${url}&http_status_code=${httpStatusCode}`;
        }

        if (forceRefetchToken) {
          url = `${url}&force_refetch_token=${forceRefetchToken}`;
        }

        return url;
      },
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.webhook_logs,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['WebhookLogs'],
    }),
    getWebhookLogsHttpStatusCodes: builder.query({
      query: ({ webhookID, caseID = null, forceRefetchToken = null }) => {
        let url = `/webhooks/${webhookID}/http_status_codes`;

        if (caseID) {
          url = `${url}?case_id=${caseID}`;
        }

        if (forceRefetchToken) {
          url = `${url}${caseID ? '&' : '?'}force_refetch_token=${forceRefetchToken}`;
        }

        return url;
      },
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['WebhookLogs'],
    }),
    getWebhookLog: builder.query({
      query: ({ webhookID, webhookLogID }) => `/webhooks/${webhookID}/logs/${webhookLogID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) =>
        response.webhook_logs.data.length > 0 ? response.webhook_logs.data[0] : null,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['WebhookLogs'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetWebhooksQuery,
  useGetWebhookQuery,
  useGetTestDataQuery,
  useCreateWebhookMutation,
  useUpdateWebhookMutation,
  useDeleteWebhookMutation,
  useActivateWebhookMutation,
  useTestWebhookMutation,
  useGetWebhookLogsQuery,
  useGetWebhookLogsHttpStatusCodesQuery,
  useGetWebhookLogQuery,
} = mCoderWebhooksApi;
