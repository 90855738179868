import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderAttachmentApi = mCoderApi.injectEndpoints({
    tagTypes: ['Attachments'],
    endpoints: (builder) => ({
        getAttachments: builder.query({
            query: ({ caseID }) => `/cases/${caseID}/attachments`,
            transformResponse: (response, meta, arg) => response.attachments,
            transformErrorResponse: customTransformErrorResponse,
            providesTags: (result, error, arg) => [{ type: 'Attachments', id: arg.caseID }],
        }),
        addAttachment: builder.mutation({
            query: ({ caseID, formData }) => ({
                url: `/cases/${caseID}/attachments`,
                method: 'POST',
                body: formData,
            }),
            transformErrorResponse: customTransformErrorResponse,
            invalidatesTags: (result, error, arg) => [
                { type: 'Attachments', id: arg.caseID },
                { type: 'Events', id: arg.caseID },
            ],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetAttachmentsQuery,
    useAddAttachmentMutation
} = mCoderAttachmentApi;
