import { useCallback } from 'react';
import { useSelector } from 'react-redux';
// api
import { usePostCommentMutation } from '../../apis/mCoderApi';
// selector
import { selectUserID } from '../../auth/authSlice';
// hooks
import { useErrorNotice, useSuccessNotice, useWarningNotice } from '../../../hooks';

export function usePostCommentHandler(caseID, handleSuccess = () => {}) {
  const noticeError = useErrorNotice();
  const noticeSuccess = useSuccessNotice();
  const noticeWarning = useWarningNotice();

  const userID = useSelector(selectUserID);
  const [postComment, result] = usePostCommentMutation();
  const handle = useCallback(
    (params) => {
      const { comment, comment_to, changeFetchLog } = params;
      if (!comment || comment.trim().length === 0) {
        noticeWarning('No comment is entered.');
        handleSuccess();
        return;
      }

      postComment({ userID, caseID, comment, comment_to, changeFetchLog })
        .unwrap()
        .then(() => {
          noticeSuccess(`New comment is posted to the case (${caseID}).`);
          handleSuccess();
        })
        .catch((error) => {
          noticeError(error);
        });
    },
    [postComment, userID, caseID, noticeError, noticeSuccess, noticeWarning, handleSuccess]
  );

  return [handle, result];
}
