import { useMemo, useEffect } from 'react';
// selector
import {
  selectEnableAutoUnassignConfig,
  selectDelayCloseHoursConfig,
  selectEnableDelayCloseConfig,
  selectEnableCoderAssignConfig,
  selectEnableReopenCaseConfig,
  selectMaxUploadSizeConfig,
  selectUXPageSizeConfig,
} from '../selectors';
// hooks
import { useErrorNotice } from '../../../hooks';
// api
import { useGetConfigsQuery } from '../../apis/mCoderApi';
// utils
import { bytesToMB, MBToBytes } from '../../../utils/units';

function useGetConfigs(select) {
  const noticeError = useErrorNotice();

  // useMemo pattern
  // https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
  const cachedSelect = useMemo(select, [select]);
  const getConfig = useGetConfigsQuery(undefined, {
    selectFromResult: (result) => ({
      // We can optionally include the other metadata fields from the result here
      ...result,
      value: cachedSelect(result),
    }),
  });
  useEffect(() => {
    if (getConfig.isError) {
      noticeError(getConfig.error);
    }
  }, [getConfig, noticeError]);

  return getConfig;
}

function useConfigKey(select) {
  const getConfigs = useGetConfigs(select);
  return [getConfigs?.value?.key, getConfigs];
}

function useConfigValue(select) {
  const getConfigs = useGetConfigs(select);
  return [getConfigs?.value?.value, getConfigs];
}

export function useEnableAutoUnassign() {
  const select = selectEnableAutoUnassignConfig;
  const [key, getConfigs] = useConfigKey(select);
  const [value] = useConfigValue(select);
  return [key, value?.toString()?.toLowerCase() === 'true', getConfigs];
}

export function useDelayCloseHours() {
  const select = selectDelayCloseHoursConfig;
  const [key, getConfigs] = useConfigKey(select);
  const [value] = useConfigValue(select);
  // 24 * 7 = 168 hours = 7 days
  return [key, value ? parseInt(value.toString(), 10) : 168, getConfigs];
}

export function useEnableDelayClose() {
  const select = selectEnableDelayCloseConfig;
  const [key, getConfigs] = useConfigKey(select);
  const [value] = useConfigValue(select);
  return [key, value?.toString()?.toLowerCase() === 'true', getConfigs];
}

export function useEnableCoderAssign() {
  const select = selectEnableCoderAssignConfig;
  const [key, getConfigs] = useConfigKey(select);
  const [value] = useConfigValue(select);
  return [key, value?.toString()?.toLowerCase() === 'true', getConfigs];
}

export function useEnableReopenCase() {
  const select = selectEnableReopenCaseConfig;
  const [key, getConfigs] = useConfigKey(select);
  const [value] = useConfigValue(select);
  return [key, value?.toString()?.toLowerCase() === 'true', getConfigs];
}

export function useUXPageSize() {
  const select = selectUXPageSizeConfig;
  const [key, getConfigs] = useConfigKey(select);
  const [value] = useConfigValue(select);
  return [key, value ? parseInt(value.toString(), 10) : 50, getConfigs];
}

export function useMaxUploadSizeInBytes() {
  const select = selectMaxUploadSizeConfig;
  const [key, getConfigs] = useConfigKey(select);
  const [value] = useConfigValue(select);
  return [key, value ? parseInt(value.toString(), 10) : MBToBytes(15), getConfigs];
}

export function useMaxUploadSizeInMB() {
  const [key, value, getConfigs] = useMaxUploadSizeInBytes();
  return [key, bytesToMB(value), getConfigs];
}
