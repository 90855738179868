import { useState, useCallback, useMemo } from 'react';

export function useDialog({ createDialog }) {
  const [opened, setOpened] = useState(false);
  const handleClose = useCallback(() => setOpened(false), []);
  const dialog = useMemo(() => createDialog({ opened, handleClose }), [opened, handleClose, createDialog]);
  const handleOpen = useCallback(() => setOpened(true), []);
  return [handleOpen, dialog, handleClose];
}

export function usePopover({ createPopover }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const popover = useMemo(() => createPopover({ anchorEl, handleClose }), [anchorEl, handleClose, createPopover]);
  return [handleOpen, popover, handleClose];
}

export function useDrawer({ createDrawer }) {
  const [opened, setOpened] = useState(false);
  const handleClose = useCallback(() => setOpened(false), []);
  const handleOpen = useCallback(() => setOpened(true), []);
  const drawer = useMemo(() => createDrawer({ opened, handleClose }), [opened, handleClose, createDrawer]);
  return [handleOpen, drawer, handleClose];
}
