import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
// @mui
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

AlertDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.node),
  cancelButtonText: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export function AlertDialog({
  title = null,
  content,
  children,
  actions,
  cancelButtonText = 'Ok',
  open,
  onClose: handleClose,
}) {
  const focusButtonRef = useRef(null);

  // Effect to manually set focus when the dialog becomes visible
  useEffect(() => {
    if (open) {
      // This timeout ensures that focus is shifted after all rendering and transitions are complete
      const timer = setTimeout(() => {
        focusButtonRef.current?.focus();
      }, 300); // Adjust timing as needed based on your dialog's animation speed

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title ?? 'Alert'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button ref={focusButtonRef} variant="contained" color="primary" size="small" onClick={handleClose} autoFocus>
          {cancelButtonText}
        </Button>
        {actions}
      </DialogActions>
    </Dialog>
  );
}
