import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderLoggingApi = mCoderApi.injectEndpoints({
  tagTypes: ['User_Events', 'FetchLogs'],
  endpoints: (builder) => ({
    getUserEvents: builder.query({
      query: ({ userID, eventType, skip, limit }) => {
        const qs = `/logging/${userID}/user_events?skip=${skip}&limit=${limit}`;
        return eventType ? `${qs}&event_type=${eventType}` : qs;
      },
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.user_events,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'User_Events', id: arg.userID }],
    }),
    getCaseEvents: builder.query({
      query: ({ userID, eventType, skip, limit }) => {
        const qs = `/logging/${userID}/case_events?skip=${skip}&limit=${limit}`;
        return eventType ? `${qs}&event_type=${eventType}` : qs;
      },
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.case_events,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'Events' }],
    }),
    saveFetchLog: builder.mutation({
      query: ({ userID, caseID, entityType }) => ({
        url: `/logging/${userID}/fetch_logs/${caseID}`,
        method: 'POST',
        body: entityType,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [{ type: 'FetchLogs', id: `${arg.userID}_${arg.caseID}` }],
    }),
    getFetchLogs: builder.query({
      query: ({ userID, caseID }) => `/logging/${userID}/fetch_logs/${caseID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.fetch_logs,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'FetchLogs', id: `${arg.userID}_${arg.caseID}` }],
    }),
    getFetchLogsNotAffectedBySaveFetchLog: builder.query({
      query: ({ userID, caseID }) => `/logging/${userID}/fetch_logs/${caseID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.fetch_logs,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
    getProviderPreviewRequester: builder.query({
      query: ({ caseID }) => `/logging/provider_preview_requester/${caseID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response?.requester_id,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserEventsQuery,
  useGetCaseEventsQuery,
  useGetFetchLogsQuery,
  useGetFetchLogsNotAffectedBySaveFetchLogQuery,
  useSaveFetchLogMutation,
  useGetProviderPreviewRequesterQuery,
} = mCoderLoggingApi;
