import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageMyCases = Loadable(lazy(() => import('../pages/PageMyCases')));
export const PageMyCase = Loadable(lazy(() => import('../pages/PageMyCase')));

export const PageMyQueuesCases = Loadable(lazy(() => import('../pages/PageMyQueuesCases')));
export const PageMyQueuesCase = Loadable(lazy(() => import('../pages/PageMyQueuesCase')));

export const PageCommPreReviewCases = Loadable(lazy(() => import('../pages/PageCommPreReviewCases')));
export const PageCommPreReviewCase = Loadable(lazy(() => import('../pages/PageCommPreReviewCase')));
export const PageCommReview = Loadable(lazy(() => import('../pages/PageCommReview')));
export const PageCommReviewCases = Loadable(lazy(() => import('../pages/PageCommReviewCases')));
export const PageCommReviewCase = Loadable(lazy(() => import('../pages/PageCommReviewCase')));

export const PageCaseRequests = Loadable(lazy(() => import('../pages/PageCaseRequests')));
export const PageCaseRequestCases = Loadable(lazy(() => import('../pages/PageCaseRequestCases')));
export const PageCaseRequestCase = Loadable(lazy(() => import('../pages/PageCaseRequestCase')));

export const PageUsers = Loadable(lazy(() => import('../pages/PageUsers')));
export const PageUsersCases = Loadable(lazy(() => import('../pages/PageUsersCases')));
export const PageUsersCase = Loadable(lazy(() => import('../pages/PageUsersCase')));
export const PageUser = Loadable(lazy(() => import('../pages/PageUser')));

export const PageCases = Loadable(lazy(() => import('../pages/PageCases')));
export const PageCase = Loadable(lazy(() => import('../pages/PageCase')));

export const PageQueues = Loadable(lazy(() => import('../pages/PageQueues')));
export const PageQueueCases = Loadable(lazy(() => import('../pages/PageQueueCases')));
export const PageQueueCase = Loadable(lazy(() => import('../pages/PageQueueCase')));

export const PageTags = Loadable(lazy(() => import('../pages/PageTags')));
export const PageDashboards = Loadable(lazy(() => import('../pages/PageDashboards')));
export const PageReportsClientInventory = Loadable(lazy(() => import('../pages/PageReportsClientInventory')));
export const PageReportsClientProcessingTime = Loadable(lazy(() => import('../pages/PageReportsClientProcessingTime')));
export const PageReportsUserInventory = Loadable(lazy(() => import('../pages/PageReportsUserInventory')));
export const PageReportsUserProcessingTime = Loadable(lazy(() => import('../pages/PageReportsUserProcessingTime')));
export const PageReportsAllocationDetails = Loadable(lazy(() => import('../pages/PageReportsAllocationDetails')));
export const PageReportsInternalPending = Loadable(lazy(() => import('../pages/PageReportsInternalPending')));
export const PageReportsClarificationResponses = Loadable(
  lazy(() => import('../pages/PageReportsClarificationResponses'))
);
export const PageReportsUserWiseAllocation = Loadable(lazy(() => import('../pages/PageReportsUserWiseAllocation')));
export const PageReportsDailyProductionSummary = Loadable(
  lazy(() => import('../pages/PageReportsDailyProductionSummary'))
);
export const PageReportsUserWiseProduction = Loadable(lazy(() => import('../pages/PageReportsUserWiseProduction')));

export const PageClientGroups = Loadable(lazy(() => import('../pages/PageClientGroups')));
export const PageClients = Loadable(lazy(() => import('../pages/PageClients')));
export const PageClientsCases = Loadable(lazy(() => import('../pages/PageClientsCases')));
export const PageClientsCase = Loadable(lazy(() => import('../pages/PageClientsCase')));
export const PageDoctors = Loadable(lazy(() => import('../pages/PageDoctors')));
export const PagePatients = Loadable(lazy(() => import('../pages/PagePatients')));

export const PageWebhooks = Loadable(lazy(() => import('../pages/PageWebhooks')));
export const PageWebhook = Loadable(lazy(() => import('../pages/PageWebhook')));
export const PageWebhookLogs = Loadable(lazy(() => import('../pages/PageWebhookLogs')));
export const PageWebhookLog = Loadable(lazy(() => import('../pages/PageWebhookLog')));

export const PageApiKeys = Loadable(lazy(() => import('../pages/PageApiKeys')));
export const PageSystemConfig = Loadable(lazy(() => import('../pages/PageConfig')));
export const PageAppearance = Loadable(lazy(() => import('../pages/PageAppearance')));
export const PageImpersonate = Loadable(lazy(() => import('../pages/PageImpersonate')));
export const PageProfile = Loadable(lazy(() => import('../pages/PageProfile')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
