import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const { themeLayout, themeMode } = useSettingsContext();

    let logoUrl = '/logo/minset-allmodes-bloom-v1.0.svg';
    let width = 40;
    let height = 40;

    if (themeLayout !== 'mini') {
        logoUrl =
            themeMode === 'light'
                ? '/logo/logo-1.1-mcoder-1.svg'
                : '/logo/logo-1.1-mcoder-1-lightblue.svg';
        height = 50;
        width = (1280 / 388) * height;
    }

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
        <Box
            ref={ref}
            component="img"
            src={logoUrl}
            sx={{
                width: { width },
                height: { height },
                display: 'inline-flex',
                ...sx,
            }}
            {...other}
        />
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
            {logo}
        </Link>
    );
});

Logo.propTypes = {
    sx: PropTypes.object,
    disabledLink: PropTypes.bool,
};

export default Logo;
