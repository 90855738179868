import { useEffect, useMemo } from 'react';
// api
import { useGetSettingsQuery } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice } from '../../../hooks';
// selectors
import {
  selectSystemAccounts,
  selectAllAssignedFilters,
  selectNoAssignedFilters,
  selectEventTypeText,
  selectAllEventTypes,
  selectStatusText,
  selectStatusList,
} from '../settingsSelectors';
import { STATUS, STATUS_TEXT__ALL } from '../../../constants';

export function useGetSettings(opts) {
  const getSettings = useGetSettingsQuery(undefined, opts);

  const noticeError = useErrorNotice();
  useEffect(() => {
    if (getSettings.isError) {
      noticeError(getSettings.error);
    }
  }, [noticeError, getSettings]);

  return getSettings;
}

function useGetSettingsQueryWithSelector(select, ...args) {
  // useMemo pattern
  // https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
  const cachedSelect = useMemo(select, [select]);
  const getSettings = useGetSettings({
    selectFromResult: (result) => ({
      // We can optionally include the other metadata fields from the result here
      ...result,
      // Include a field called `user` in the hook result object,
      // which will be a specific user
      value: cachedSelect(result, ...args),
    }),
  });
  return [getSettings.value, getSettings];
}

export function useSystemAccounts() {
  const [value, getSettings] = useGetSettingsQueryWithSelector(selectSystemAccounts);
  return [value ?? {}, getSettings];
}

export function useAllAssignedFilters() {
  const [value, getSettings] = useGetSettingsQueryWithSelector(selectAllAssignedFilters);
  return [value ?? [], getSettings];
}

export function useNoAssignedFilters() {
  const [value, getSettings] = useGetSettingsQueryWithSelector(selectNoAssignedFilters);
  return [value ?? [], getSettings];
}

export function useEventTypeText(event_type) {
  const [value, getSettings] = useGetSettingsQueryWithSelector(selectEventTypeText, event_type);
  return [value, getSettings];
}

export function useAllEventTypes() {
  const [value, getSettings] = useGetSettingsQueryWithSelector(selectAllEventTypes);
  return [value ?? [], getSettings];
}

export function useStatusText(status) {
  const [value, getSettings] = useGetSettingsQueryWithSelector(selectStatusText, status);
  return [value, getSettings];
}

export function useStatusTextMapping() {
  const [STATUS_TEXT__REQ_MANUAL_CODING, get_] = useStatusText(STATUS.REQ_MANUAL_CODING);
  const [STATUS_TEXT__HOLD] = useStatusText(STATUS.HOLD);
  const [STATUS_TEXT__COMMITTED] = useStatusText(STATUS.COMMITTED);
  const [STATUS_TEXT__CLOSED] = useStatusText(STATUS.CLOSED);
  const [STATUS_TEXT__PROVIDER_PREREVIEW] = useStatusText(STATUS.PROVIDER_PREREVIEW);
  const [STATUS_TEXT__PROVIDER_HOLD] = useStatusText(STATUS.PROVIDER_HOLD);
  const [STATUS_TEXT__PROVIDER_REVIEW] = useStatusText(STATUS.PROVIDER_REVIEW);
  const [STATUS_TEXT__PROVIDER_REVIEWED] = useStatusText(STATUS.PROVIDER_REVIEWED);
  return [
    {
      [STATUS.REQ_MANUAL_CODING]: STATUS_TEXT__REQ_MANUAL_CODING,
      [STATUS.HOLD]: STATUS_TEXT__HOLD,
      [STATUS.COMMITTED]: STATUS_TEXT__COMMITTED,
      [STATUS.CLOSED]: STATUS_TEXT__CLOSED,
      [STATUS.PROVIDER_PREREVIEW]: STATUS_TEXT__PROVIDER_PREREVIEW,
      [STATUS.PROVIDER_HOLD]: STATUS_TEXT__PROVIDER_HOLD,
      [STATUS.PROVIDER_REVIEW]: STATUS_TEXT__PROVIDER_REVIEW,
      [STATUS.PROVIDER_REVIEWED]: STATUS_TEXT__PROVIDER_REVIEWED,
    },
    get_,
  ];
}

export function useStatusList(keepStatus = ([status, label]) => true) {
  const [value, getSettings] = useGetSettingsQueryWithSelector(selectStatusList);
  return [(value ?? []).filter(keepStatus), getSettings];
}

export function useCoderStatusList() {
  const [statusList, getSettings] = useStatusList(([status, label]) =>
    [STATUS.REQ_MANUAL_CODING, STATUS.HOLD, STATUS.COMMITTED, STATUS.CLOSED].includes(status)
  );
  return [statusList, getSettings];
}

export function useCoordinatorStatusList(includesProvider = true) {
  const [statusList, getSettings] = useStatusList(([status, label]) =>
    [
      STATUS.PROVIDER_PREREVIEW,
      STATUS.PROVIDER_REVIEWED,
      ...(includesProvider ? [STATUS.PROVIDER_REVIEW, STATUS.PROVIDER_HOLD] : []),
    ].includes(status)
  );
  return [statusList, getSettings];
}

export function useProviderStatusList() {
  const [statusList, getSettings] = useStatusList(([status, label]) =>
    [STATUS.PROVIDER_REVIEW, STATUS.PROVIDER_HOLD].includes(status)
  );
  return [statusList, getSettings];
}

export function useAllStatusList(includesAll = true) {
  const [coderStatusList, getSettings] = useCoderStatusList();
  const [coordinatorStatusList] = useCoordinatorStatusList();
  return [
    [...(includesAll ? [[STATUS.ALL, STATUS_TEXT__ALL]] : []), ...coderStatusList, ...coordinatorStatusList],
    getSettings,
  ];
}
