import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { msalConfig, graphMeResource, mCoderApiResource } from '../../auth/MsalConfig';

function createAcquireToken(scopes) {
  // We can't use Hook out of React Component, so useMsal can't be used here.
  // As a workaround, we directly call on a new Msal instance
  // Read: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2927
  const msalInstance = new PublicClientApplication(msalConfig);
  return async () => {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
    const request = { scopes, account };
    const response = await msalInstance.acquireTokenSilent(request).catch(async (error) => {
      console.log(error, error instanceof InteractionRequiredAuthError);
      return msalInstance.acquireTokenRedirect(request);
    });
    return response.accessToken;
  };
}

export const acquireGraphMeToken = createAcquireToken(graphMeResource.scopes);
export const acquireMCoderApiToken = createAcquireToken(mCoderApiResource.scopes);
