import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderRulesApi = mCoderApi.injectEndpoints({
  endpoints: (builder) => ({
    applyRules: builder.mutation({
      query: ({ ruleGroupID = 1, caseID, humanResult }) => ({
        url: `/rule_groups/${ruleGroupID}/cases/${caseID}`,
        method: 'POST',
        body: humanResult,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
  }),
  overrideExisting: false,
});

export const { useApplyRulesMutation } = mCoderRulesApi;
