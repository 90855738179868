// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: path(ROOTS, 'login'),
};

export const PATHS = {
  root: ROOTS,
  login: path(ROOTS, 'login'),
  profile: path(ROOTS, 'profile'),
  insight: {
    root: path(ROOTS, 'insight/dashboards'),
    dashboards: path(ROOTS, 'insight/dashboards'),
    clients_view: {
      root: path(ROOTS, 'insight/clients_view/inventory'),
      inventory: path(ROOTS, 'insight/clients_view/inventory'),
      processing_time: path(ROOTS, 'insight/clients_view/processing_time'),
    },
    users_view: {
      root: path(ROOTS, 'insight/users_view/inventory'),
      inventory: path(ROOTS, 'insight/users_view/inventory'),
      processing_time: path(ROOTS, 'insight/users_view/processing_time'),
    },
    operation_reports: {
      root: path(ROOTS, 'insight/operation_reports/allocation_details'),
      allocation_details: path(ROOTS, 'insight/operation_reports/allocation_details'),
      internal_pending: path(ROOTS, 'insight/operation_reports/internal_pending'),
      clarification_responses: path(ROOTS, 'insight/operation_reports/clarification_responses'),
      user_wise_allocation: path(ROOTS, 'insight/operation_reports/user_wise_allocation'),
      user_wise_production: path(ROOTS, 'insight/operation_reports/user_wise_production'),
      daily_production_summary: path(ROOTS, 'insight/operation_reports/daily_production_summary'),
    },
  },
  cases: path(ROOTS, 'cases'),
  queues2: path(ROOTS, 'queues2'),
  clients: path(ROOTS, 'clients'),
  clients_cases: path(ROOTS, 'clients_cases'),
  clientGroups: path(ROOTS, 'client_groups'),
  patients: path(ROOTS, 'patients'),
  doctors: path(ROOTS, 'doctors'),
  users: path(ROOTS, 'users'),
  users_cases: path(ROOTS, 'users_cases'),
  tags: path(ROOTS, 'tags'),
  case_requests: path(ROOTS, 'case_requests'),
  workspaces: {
    root: path(ROOTS, 'workspaces'),
    my_cases: path(ROOTS, 'workspaces/cases'),
    my_queues: path(ROOTS, 'workspaces/queues'),
  },
  communications: {
    root: path(ROOTS, 'communications'),
    prereview: path(ROOTS, 'communications/prereview'),
    review: path(ROOTS, 'communications/review'),
  },
  settings: {
    root: path(ROOTS, 'settings'),
    apiKeys: path(ROOTS, 'settings/apikeys'),
    webhooks: path(ROOTS, 'settings/webhooks'),
    system_config: path(ROOTS, 'settings/system_config'),
    appearance: path(ROOTS, 'settings/appearance'),
    impersonate: path(ROOTS, 'settings/impersonate'),
  },
};
