import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// layouts
import CompactLayout from '../layouts/compact';
import MainLayout from '../layouts/main';
// config
import {
  ROLE_ADMIN,
  ROLE_CODER_MANAGER,
  ROLE_AUDITOR,
  ROLE_CODER,
  ROLE_MEMBER,
  ROLE_PROVIDER,
  ROLE_COORDINATOR,
} from '../config-global';
//
import {
  Page404,
  PageMyCases,
  PageMyCase,
  PageMyQueuesCases,
  PageMyQueuesCase,
  PageCommPreReviewCases,
  PageCommPreReviewCase,
  PageCommReview,
  PageCommReviewCases,
  PageCommReviewCase,
  PageUser,
  PageUsers,
  PageUsersCases,
  PageUsersCase,
  PageCaseRequestCases,
  PageCaseRequests,
  PageCaseRequestCase,
  PageCase,
  PageCases,
  PageQueueCase,
  PageQueueCases,
  PageQueues,
  PageTags,
  PageDashboards,
  PageReportsClientInventory,
  PageReportsAllocationDetails,
  PageReportsClarificationResponses,
  PageReportsInternalPending,
  PageReportsClientProcessingTime,
  PageReportsUserInventory,
  PageReportsUserProcessingTime,
  PageReportsUserWiseAllocation,
  PageReportsUserWiseProduction,
  PageReportsDailyProductionSummary,
  PageClients,
  PageClientsCases,
  PageClientsCase,
  PageClientGroups,
  PageDoctors,
  PagePatients,
  PageWebhooks,
  PageWebhook,
  PageWebhookLogs,
  PageWebhookLog,
  PageImpersonate,
  PageApiKeys,
  PageAppearance,
  PageProfile,
  PageSystemConfig,
  LoginPage,
} from './elements';
// routes
import { PATHS } from './paths';
import {
  selectUserIsCoder,
  selectUserIsProvider,
  selectUserIsAdmin,
  selectUserIsCoderManager,
  selectUserIsAuditor,
  selectUserIsCoordinator,
} from '../features/auth/authSlice';

// ----------------------------------------------------------------------

export default function Router() {
  const isCoderRole = useSelector(selectUserIsCoder);
  const isProviderRole = useSelector(selectUserIsProvider);
  const isAdminRole = useSelector(selectUserIsAdmin);
  const isCoderManager = useSelector(selectUserIsCoderManager);
  const isAuditor = useSelector(selectUserIsAuditor);
  const isCoordinator = useSelector(selectUserIsCoordinator);

  let pathAfterLogin = PATHS.login;
  if (isCoderManager) {
    pathAfterLogin = PATHS.insight.users_view.inventory;
  } else if (isAuditor) {
    pathAfterLogin = PATHS.cases;
  } else if (isCoderRole) {
    pathAfterLogin = PATHS.workspaces.my_cases;
  } else if (isCoordinator) {
    pathAfterLogin = PATHS.communications.prereview;
  } else if (isProviderRole) {
    pathAfterLogin = PATHS.communications.review;
  } else if (isAdminRole) {
    pathAfterLogin = PATHS.case_requests;
  }

  return useRoutes([
    {
      path: '/',
      children: [
        {
          element: <Navigate to={pathAfterLogin} replace />,
          index: true,
        },
        {
          path: 'login',
          element: (
            <GuestGuard urlIfAuthenticated={pathAfterLogin}>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'profile',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[
                    ROLE_ADMIN,
                    ROLE_MEMBER,
                    ROLE_CODER,
                    ROLE_CODER_MANAGER,
                    ROLE_AUDITOR,
                    ROLE_COORDINATOR,
                    ROLE_PROVIDER,
                  ]}
                >
                  <PageProfile />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          element: <CompactLayout />,
          children: [{ path: '404', element: <Page404 /> }],
        },
        {
          path: 'insight',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: 'dashboards',
              element: (
                // https://github.com/minset-ai/mCoder-Portal/issues/254
                // Need to allow the coordinator to access the dashboard
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_COORDINATOR]}>
                  <PageDashboards />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'clients_view',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_COORDINATOR]}>
                  <PageReportsClientInventory />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'clients_view/inventory',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_COORDINATOR]}>
                  <PageReportsClientInventory />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'clients_view/processing_time',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_COORDINATOR]}>
                  <PageReportsClientProcessingTime />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'users_view',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsUserInventory />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'users_view/inventory',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsUserInventory />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'users_view/processing_time',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsUserProcessingTime />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'users_view/inventory',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_COORDINATOR]}>
                  <PageReportsUserInventory />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'operation_reports',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsUserInventory />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'operation_reports/allocation_details',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsAllocationDetails />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'operation_reports/internal_pending',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsInternalPending />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'operation_reports/clarification_responses',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsClarificationResponses />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'operation_reports/user_wise_allocation',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsUserWiseAllocation />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'operation_reports/user_wise_production',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsUserWiseProduction />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'operation_reports/daily_production_summary',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageReportsDailyProductionSummary />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'case_requests',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageCaseRequests />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':reqID/cases',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageCaseRequestCases />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':reqID/cases/:caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageCaseRequestCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'cases',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageCases />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR, ROLE_CODER, ROLE_PROVIDER]}>
                  <PageCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'users',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_ADMIN, ROLE_CODER_MANAGER]}>
                  <PageUsers />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':userID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_ADMIN, ROLE_CODER_MANAGER]}>
                  <PageUser />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.users_cases,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageUsersCases />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageUsersCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.workspaces.my_cases,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER]}>
                  <PageMyCases />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER]}>
                  <PageMyCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.workspaces.my_queues,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER]}>
                  <PageMyQueuesCases />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'cases',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER]}>
                  <PageMyQueuesCases />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'cases/:caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER]}>
                  <PageMyQueuesCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.communications.prereview,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_COORDINATOR]}>
                  <PageCommPreReviewCases />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'cases',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_COORDINATOR]}>
                  <PageCommPreReviewCases />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'cases/:caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_COORDINATOR]}>
                  <PageCommPreReviewCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.communications.review,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_PROVIDER]}>
                  <PageCommReview />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'cases',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_PROVIDER]}>
                  <PageCommReviewCases />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'cases/:caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_PROVIDER]}>
                  <PageCommReviewCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.queues2,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageQueues />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':queueID/cases',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageQueueCases />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':queueID/cases/:caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageQueueCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.clients,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageClients />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: PATHS.clients_cases,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageClientsCases />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: ':caseID',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER, ROLE_AUDITOR]}>
                  <PageClientsCase />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATHS.clientGroups,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageClientGroups />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: PATHS.doctors,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageDoctors />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: PATHS.patients,
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PagePatients />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'tags',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_CODER_MANAGER]}>
                  <PageTags />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: 'settings',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to="/settings/tags" replace />, index: true },
            {
              path: 'impersonate',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_ADMIN]}>
                  <PageImpersonate />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'apikeys',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_ADMIN]}>
                  <PageApiKeys />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'webhooks',
              children: [
                {
                  element: (
                    <RoleBasedGuard hasContent roles={[ROLE_ADMIN]}>
                      <PageWebhooks />
                    </RoleBasedGuard>
                  ),
                  index: true,
                },
                {
                  path: ':webhookID',
                  element: (
                    <RoleBasedGuard hasContent roles={[ROLE_ADMIN]}>
                      <PageWebhook />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':webhookID/logs',
                  element: (
                    <RoleBasedGuard hasContent roles={[ROLE_ADMIN]}>
                      <PageWebhookLogs />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':webhookID/logs/:webhookLogID',
                  element: (
                    <RoleBasedGuard hasContent roles={[ROLE_ADMIN]}>
                      <PageWebhookLog />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: 'system_config',
              element: (
                <RoleBasedGuard hasContent roles={[ROLE_ADMIN]}>
                  <PageSystemConfig />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'appearance',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={[ROLE_ADMIN, ROLE_MEMBER, ROLE_CODER, ROLE_PROVIDER, ROLE_CODER_MANAGER, ROLE_AUDITOR]}
                >
                  <PageAppearance />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        // For now, we leave missing page to Azure web static app handle
        // { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
