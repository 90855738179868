import { useEffect } from 'react';
import _ from 'lodash';
// api
import { useGetCommentsQuery } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice } from '../../../hooks';
import { useUsers } from '../../users/hooks';
// config
import { ROLE_PROVIDER, ROLE_COORDINATOR } from '../../../config-global';

function useGetComments(caseID) {
  const noticeError = useErrorNotice();
  const getComments = useGetCommentsQuery({ caseID });
  useEffect(() => {
    if (getComments.isError) {
      noticeError(getComments.error);
    }
  }, [noticeError, getComments]);
  return getComments;
}

export function useComments(caseID, providerMode = false) {
  const get_ = useGetComments(caseID);
  const comments = get_?.data ?? [];

  const [users] = useUsers(false);
  if (providerMode) {
    // If the provider is reading the comments (providerMode is set to true)
    // and the comment wasn't posted by the provider or the coordinator, don't show the comment.
    const userIsProviderOrCoordinatorMap = new Map(
      users.map((user) => {
        const actualRoles = _.sortBy(user?.roles ?? []);
        return [user.id, _.includes(actualRoles, ROLE_PROVIDER) || _.includes(actualRoles, ROLE_COORDINATOR)];
      })
    );
    return [comments.filter((cmt) => userIsProviderOrCoordinatorMap.get(cmt.commenter) ?? false), get_];
  }

  return [comments, get_];
}
