import { mCoderApi, customTransformErrorResponse } from './base';

const __buildQS = (qs, k, v) => {
  if (v) {
    return qs && qs.length > 0 ? `&${k}=${v}` : `${k}=${v}`;
  }
  return '';
};

const mCoderMetricsApi = mCoderApi.injectEndpoints({
  tagTypes: ['Metrics'],
  endpoints: (builder) => ({
    getMetrics: builder.query({
      query: ({ metric_group, cate, sub_cate, metric, startDate, endDate }) => {
        let query = '';
        query += __buildQS(query, 'cate', cate);
        query += __buildQS(query, 'sub_cate', sub_cate);
        query += __buildQS(query, 'metric', metric);
        query += __buildQS(query, 'start_date', startDate);
        query += __buildQS(query, 'end_date', endDate);

        const path = `/metric_groups/${metric_group}`;
        return query && query.length ? `${path}?${query}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientInventoryMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/client/inventory';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientUnclosedCases: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/client/unclosed_cases';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientProcessingTimeMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/client/processing_time';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientStatusMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/client/status';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getClientDailyMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/client/daily';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getUserInventoryMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/user/inventory';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getUserProcessingTimeMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/user/processing_time';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getUserStatusMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/user/status';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
    getUserDailyMetrics: builder.query({
      query: ({ urlSearchParams }) => {
        const path = '/metrics/user/daily';
        return urlSearchParams ? `${path}?${urlSearchParams}` : path;
      },
      transformResponse: (response, meta, arg) => response,
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Metrics'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetMetricsQuery,
  useGetClientInventoryMetricsQuery,
  useGetClientStatusMetricsQuery,
  useGetClientUnclosedCasesQuery,
  useGetClientProcessingTimeMetricsQuery,
  useGetClientDailyMetricsQuery,
  useGetUserInventoryMetricsQuery,
  useGetUserProcessingTimeMetricsQuery,
  useGetUserStatusMetricsQuery,
  useGetUserDailyMetricsQuery,
} = mCoderMetricsApi;
