import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// config
import { MCODER_API } from '../../../config-global';
import { acquireMCoderApiToken } from '../token';

export const mCoderApi = createApi({
  reducerPath: 'mCoder',
  baseQuery: fetchBaseQuery({
    baseUrl: `${MCODER_API.baseUrl}/api`,
    prepareHeaders: async (headers, { getState }) => {
      const token = await acquireMCoderApiToken();
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: () => ({}),
});

export const customTransformErrorResponse = (response, meta, arg) => {
  if (response.data?.message) {
    console.log("response.data?.message", response.data?.message);
    return response.data?.message;
  }

  if (response.message) {
    console.log("response.message", response.message);
    return response.message;
  }

  console.log("response", response);
  return 'Server Internal Error'
};
