import { useCallback } from 'react';
// api
import { useAssignCasesToUserMutation } from '../../apis/mCoderApi';
// hooks
import { useErrorNotice, useSuccessNotice, useWarningNotice } from '../../../hooks';

export function useAssignCasesToUserHandler(caseIDList, q = null, caseCount = null, handleSuccess = () => {}) {
  const noticeError = useErrorNotice();
  const noticeSuccess = useSuccessNotice();
  const noticeWarning = useWarningNotice();
  const [assignCasesToUser, assignCasesToUserResult] = useAssignCasesToUserMutation();
  return [
    useCallback(
      (user) => {
        if ((!caseCount || caseCount === 0) && (!caseIDList || caseIDList.length === 0)) {
          noticeWarning('Please choose some cases for assignment.');
          handleSuccess();
          return;
        }

        if (user) {
          assignCasesToUser({ userID: user.id, caseIDList, q, limit: caseCount })
            .unwrap()
            .then(() => {
              noticeSuccess(`${caseIDList?.length ?? caseCount} cases are assigned to ${user.name}.`);
              handleSuccess();
            })
            .catch((error) => {
              noticeError(error);
            });
        } else {
          noticeWarning('Please choose a user for case assignment.');
          handleSuccess();
        }
      },
      [assignCasesToUser, noticeError, noticeSuccess, noticeWarning, caseIDList, q, caseCount, handleSuccess]
    ),
    assignCasesToUserResult,
  ];
}
