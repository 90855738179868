import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderTagGroupsApi = mCoderApi.injectEndpoints({
  tagTypes: ['TagGroups'],
  endpoints: (builder) => ({
    getTagGroups: builder.query({
      query: () => `/tag_groups`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.tag_groups.data,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['TagGroups'],
    }),
    addTagGroup: builder.mutation({
      query: ({ tagGroupName }) => ({
        url: `/tag_groups/${tagGroupName}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['TagGroups', 'SearchFilter'],
    }),
    deleteTagGroup: builder.mutation({
      query: ({ tagGroupName }) => ({
        url: `/tag_groups/${tagGroupName}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['TagGroups', 'SearchFilter'],
    }),
    addTag: builder.mutation({
      query: ({ tagGroupName, tagName }) => ({
        url: `/tag_groups/${tagGroupName}/tags/${tagName}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['TagGroups', 'SearchFilter'],
    }),
    deleteTag: builder.mutation({
      query: ({ tagGroupName, tagName }) => ({
        url: `/tag_groups/${tagGroupName}/tags/${tagName}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['TagGroups', 'SearchFilter'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTagGroupsQuery,
  useAddTagGroupMutation,
  useDeleteTagGroupMutation,
  useAddTagMutation,
  useDeleteTagMutation,
} = mCoderTagGroupsApi;
