import { useEffect, useState } from 'react';
// hooks
import { useUXPageSize } from './useGetConfigs';

export function usePageSize(defaultValue) {
  const [, defaultTablePageSize] = useUXPageSize();
  const [pageSize, setPageSize] = useState(defaultValue);
  useEffect(() => {
    setPageSize(defaultTablePageSize);
  }, [setPageSize, defaultTablePageSize]);
  return [pageSize, setPageSize];
}
