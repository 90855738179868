import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderApiKeysApi = mCoderApi.injectEndpoints({
  tagTypes: ['ApiKeys'],
  endpoints: (builder) => ({
    getApiKeys: builder.query({
      query: () => '/apikeys',
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.apikeys,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['ApiKeys'],
    }),
    regenerateApiKey: builder.mutation({
      query: (type) => ({
        url: `/apikeys/${type}`,
        method: 'POST',
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.apikeys,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: ['ApiKeys'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetApiKeysQuery, useRegenerateApiKeyMutation } = mCoderApiKeysApi;
