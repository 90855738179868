import { useSelector } from 'react-redux';
// @mui
import WorkspacesTwoToneIcon from '@mui/icons-material/WorkspacesTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import LogoDevTwoToneIcon from '@mui/icons-material/LogoDevTwoTone';
import Groups3TwoToneIcon from '@mui/icons-material/Groups3TwoTone';
// components
import SvgColor from '../../../../components/svg-color';
// selector
import { selectUserIsAdmin, selectUserIsCoordinator } from '../../../../features/auth/authSlice';
// routes
import { PATHS } from '../../../../routes/paths';
// config
import {
  ROLE_ADMIN,
  ROLE_CODER_MANAGER,
  ROLE_AUDITOR,
  ROLE_CODER,
  ROLE_PROVIDER,
  ROLE_COORDINATOR,
  MENU_TEXT__PROVIDER,
  MENU_TEXT__COORDINATOR,
} from '../../../../config-global';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const ICONS = {
  workspace: <WorkspacesTwoToneIcon />,
  communication: <QuestionAnswerTwoToneIcon />,
  analytics: icon('ic_analytics'),
  management: <ListAltTwoToneIcon />,
  setting: <SettingsTwoToneIcon />,
};

export function useRoleBasedNavConfig() {
  const isAdminRole = useSelector(selectUserIsAdmin);
  const isCoordinatorRole = useSelector(selectUserIsCoordinator);
  const isOpened = (paths) => (pathname) => paths.some((path) => pathname.startsWith(path));

  const navConfig = [
    {
      subheader: null,
      items: [
        {
          title: 'Workspace',
          path: PATHS.workspaces.my_cases,
          icon: ICONS.workspace,
          children: [
            { title: 'My Cases', path: PATHS.workspaces.my_cases },
            { title: 'My Queues', path: PATHS.workspaces.my_queues },
          ],
          roles: [ROLE_CODER],
          isOpened: isOpened([PATHS.workspaces.my_cases, PATHS.workspaces.my_queues]),
        },
        {
          title: 'Communication',
          path: isCoordinatorRole ? PATHS.communications.prereview : PATHS.communications.review,
          icon: ICONS.communication,
          children: [
            { title: MENU_TEXT__COORDINATOR, path: PATHS.communications.prereview, roles: [ROLE_COORDINATOR] },
            { title: MENU_TEXT__PROVIDER, path: PATHS.communications.review, roles: [ROLE_PROVIDER] },
          ],
          roles: [ROLE_COORDINATOR, ROLE_PROVIDER],
          isOpened: isOpened([PATHS.communications.prereview, PATHS.communications.review]),
        },
        {
          title: 'Cases',
          path: PATHS.cases,
          icon: ICONS.management,
          children: [
            { title: 'Inflow', path: PATHS.case_requests, roles: [ROLE_CODER_MANAGER, ROLE_AUDITOR] },
            { title: 'Cases', path: PATHS.cases, roles: [ROLE_CODER_MANAGER, ROLE_AUDITOR] },
            { title: 'Queues', path: PATHS.queues2, roles: [ROLE_CODER_MANAGER, ROLE_AUDITOR] },
            { title: 'Users View', path: PATHS.users_cases, roles: [ROLE_CODER_MANAGER, ROLE_AUDITOR] },
            { title: 'Clients View', path: PATHS.clients_cases, roles: [ROLE_CODER_MANAGER, ROLE_AUDITOR] },
          ],
          roles: [ROLE_CODER_MANAGER, ROLE_AUDITOR],
          isOpened: isOpened([PATHS.case_requests, PATHS.cases, PATHS.queues2, PATHS.users_cases, PATHS.clients_cases]),
        },
        {
          title: 'Clients',
          path: PATHS.clients,
          icon: <Groups3TwoToneIcon />,
          children: [
            { title: 'Groups', path: PATHS.clientGroups, roles: [ROLE_CODER_MANAGER] },
            { title: 'Clients', path: PATHS.clients, roles: [ROLE_CODER_MANAGER] },
            // { title: 'Doctors', path: PATHS.doctors, roles: [ROLE_CODER_MANAGER] },
            // { title: 'Patients', path: PATHS.patients, roles: [ROLE_CODER_MANAGER] },
          ],
          roles: [ROLE_CODER_MANAGER],
          isOpened: isOpened([PATHS.clientGroups, PATHS.clients, PATHS.doctors, PATHS.patients]),
        },
        {
          title: 'Insight',
          path: PATHS.insight.dashboards,
          icon: ICONS.analytics,
          children: [
            { title: 'Dashboards', path: PATHS.insight.dashboards },
            {
              title: 'Clients View',
              path: PATHS.insight.clients_view.inventory,
              children: [
                { title: 'Inventory', path: PATHS.insight.clients_view.inventory },
                { title: 'Processing Time', path: PATHS.insight.clients_view.processing_time },
              ],
              isOpened: isOpened([
                PATHS.insight.clients_view,
                PATHS.insight.clients_view.inventory,
                PATHS.insight.clients_view.processing_time,
              ]),
            },
            {
              title: 'Users View',
              path: PATHS.insight.users_view.inventory,
              children: [
                { title: 'Inventory', path: PATHS.insight.users_view.inventory },
                { title: 'Processing Time', path: PATHS.insight.users_view.processing_time },
              ],
              isOpened: isOpened([
                PATHS.insight.users_view,
                PATHS.insight.users_view.inventory,
                PATHS.insight.users_view.processing_time,
              ]),
            },
            {
              title: 'Operation Reports',
              path: PATHS.insight.operation_reports.allocation_details,
              children: [
                { title: 'Allocation Details', path: PATHS.insight.operation_reports.allocation_details },
                { title: 'Internal Pending', path: PATHS.insight.operation_reports.internal_pending },
                { title: 'Clarification Responses', path: PATHS.insight.operation_reports.clarification_responses },
                { title: 'User Wise Allocation', path: PATHS.insight.operation_reports.user_wise_allocation },
                { title: 'User Wise Production', path: PATHS.insight.operation_reports.user_wise_production },
                { title: 'Daily Production Summary', path: PATHS.insight.operation_reports.daily_production_summary },
              ],
              isOpened: isOpened([
                PATHS.insight.operation_reports,
                PATHS.insight.operation_reports.allocation_details,
                PATHS.insight.operation_reports.internal_pending,
                PATHS.insight.operation_reports.clarification_responses,
                PATHS.insight.operation_reports.user_wise_allocation,
                PATHS.insight.operation_reports.user_wise_production,
                PATHS.insight.operation_reports.daily_production_summary,
              ]),
            },
          ],
          roles: [ROLE_COORDINATOR, ROLE_CODER_MANAGER],
          isOpened: isOpened([
            PATHS.insight.dashboards,
            PATHS.insight.clients_view,
            PATHS.insight.clients_view.inventory,
            PATHS.insight.clients_view.processing_time,
            PATHS.insight.users_view,
            PATHS.insight.users_view.inventory,
            PATHS.insight.users_view.processing_time,
            PATHS.insight.operation_reports,
            PATHS.insight.operation_reports.allocation_details,
            PATHS.insight.operation_reports.internal_pending,
            PATHS.insight.operation_reports.clarification_responses,
            PATHS.insight.operation_reports.user_wise_allocation,
            PATHS.insight.operation_reports.user_wise_production,
            PATHS.insight.operation_reports.daily_production_summary,
          ]),
        },
        {
          title: 'Settings',
          path: isAdminRole ? PATHS.users : PATHS.settings.appearance,
          icon: ICONS.setting,
          children: [
            { title: 'Users', path: PATHS.users, roles: [ROLE_CODER_MANAGER, ROLE_ADMIN] },
            { title: 'Tags', path: PATHS.tags, roles: [ROLE_CODER_MANAGER] },
            { title: 'Api Keys', path: PATHS.settings.apiKeys, roles: [ROLE_ADMIN] },
            { title: 'Webhooks', path: PATHS.settings.webhooks, roles: [ROLE_ADMIN] },
            { title: 'System', path: PATHS.settings.system_config, roles: [ROLE_ADMIN] },
            { title: 'Appearance', path: PATHS.settings.appearance },
          ],
          isOpened: isOpened([
            PATHS.users,
            PATHS.tags,
            PATHS.settings.apiKeys,
            PATHS.settings.webhooks,
            PATHS.settings.system_config,
            PATHS.settings.appearance,
          ]),
        },
        {
          title: 'Diagnosis',
          path: PATHS.settings.impersonate,
          icon: <LogoDevTwoToneIcon />,
          children: [{ title: 'Impersonate', path: PATHS.settings.impersonate, roles: [ROLE_ADMIN] }],
          roles: [ROLE_ADMIN],
          isOpened: isOpened([PATHS.settings.impersonate]),
        },
      ],
    },
  ];

  return navConfig;
}
