import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderQueues2Api = mCoderApi.injectEndpoints({
  tagTypes: ['Queues2', 'Queues2_Users'],
  endpoints: (builder) => ({
    getQueues2: builder.query({
      query: () => `/queues2`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.queues2,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Queues2'],
    }),
    getQueue2: builder.query({
      query: ({ queueIDOrUserID }) => `/queues2/${queueIDOrUserID}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) =>
        response.queues2 && response.queues2.length > 0 ? response.queues2[0] : null,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Queues2'],
    }),
    updateQueue: builder.mutation({
      query: ({ queueID, accessType }) => ({
        url: `/queues2/${queueID}`,
        method: 'POST',
        body: accessType,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2'],
    }),
    createQueue: builder.mutation({
      query: ({ name, description, userID }) => ({
        url: `/queues2`,
        method: 'POST',
        body: {
          name,
          description,
          user_id: userID,
        },
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2'],
    }),
    setDefaultQueue: builder.mutation({
      query: ({ queueID }) => ({
        url: `/queues2/${queueID}/default`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2'],
    }),
    deleteQueue: builder.mutation({
      query: ({ queueID }) => ({
        url: `/queues2/${queueID}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2'],
    }),
    addCasesToQueue: builder.mutation({
      query: ({ queueID, caseIDList }) => ({
        url: `/queues2/${queueID}/cases`,
        method: 'POST',
        body: caseIDList,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        'Queues2',
        'Cases',
        ...arg.caseIDList.map((caseID) => ({ type: 'Events', id: arg.caseID })),
        ...arg.caseIDList.map((caseID) => ({ type: 'CaseDetail', id: arg.caseID })),
      ],
    }),
    removeCasesFromQueue: builder.mutation({
      query: ({ queueID, caseIDList }) => ({
        url: `/queues2/${queueID}/cases`,
        method: 'DELETE',
        body: caseIDList,
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [
        'Queues2',
        'Cases',
        ...arg.caseIDList.map((caseID) => ({ type: 'Events', id: arg.caseID })),
        ...arg.caseIDList.map((caseID) => ({ type: 'CaseDetail', id: arg.caseID })),
      ],
    }),
    getAllowedUsers: builder.query({
      query: ({ queueID }) => `/queues2/${queueID}/allowed_users`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.users,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'Queues2_Users', id: arg.queueID }],
    }),
    addUsersToAllowedList: builder.mutation({
      query: ({ queueID, userID }) => ({
        url: `/queues2/${queueID}/allowed_users/${userID}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2', { type: 'Queues2_Users', id: arg.queueID }],
    }),
    removeUsersFromAllowedList: builder.mutation({
      query: ({ queueID, userID }) => ({
        url: `/queues2/${queueID}/allowed_users/${userID}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2', { type: 'Queues2_Users', id: arg.queueID }],
    }),
    getDisallowedUsers: builder.query({
      query: ({ queueID }) => `/queues2/${queueID}/disallowed_users`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.users,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: (result, error, arg) => [{ type: 'Queues2_Users', id: arg.queueID }],
    }),
    addUsersToDisallowedList: builder.mutation({
      query: ({ queueID, userID }) => ({
        url: `/queues2/${queueID}/disallowed_users/${userID}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2', { type: 'Queues2_Users', id: arg.queueID }],
    }),
    removeUsersFromDisallowedList: builder.mutation({
      query: ({ queueID, userID }) => ({
        url: `/queues2/${queueID}/disallowed_users/${userID}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Queues2', { type: 'Queues2_Users', id: arg.queueID }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetQueues2Query,
  useGetQueue2Query,
  useUpdateQueueMutation,
  useSetDefaultQueueMutation,
  useLazyGetQueue2Query,
  useCreateQueueMutation,
  useDeleteQueueMutation,
  useAddCasesToQueueMutation,
  useRemoveCasesFromQueueMutation,
  useGetAllowedUsersQuery,
  useAddUsersToAllowedListMutation,
  useRemoveUsersFromAllowedListMutation,
  useGetDisallowedUsersQuery,
  useAddUsersToDisallowedListMutation,
  useRemoveUsersFromDisallowedListMutation,
} = mCoderQueues2Api;
