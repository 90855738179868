import { createSelector } from '@reduxjs/toolkit';

const emptyUsers = [];

export const selectAllUsers = createSelector(
  [(res) => res?.data],
  (getUsersResult) => getUsersResult ?? emptyUsers
);

export const selectUserByID = () =>
  createSelector([selectAllUsers, (state, userID) => userID], (allUsers, userID) => {
    if (userID) {
      return allUsers.find((user) => user.id === userID);
    }
    return null;
  });

export const selectUsersByRole = () =>
  createSelector([selectAllUsers, (state, role) => role], (allUsers, role) =>
    role ? allUsers.filter((user) => Boolean((user.roles ?? []).includes(role))) : allUsers
  );

export const selectUsers = () =>
  createSelector([selectAllUsers, (state, predicate) => predicate], (allUsers, predicate) =>
    allUsers.filter(predicate)
  );
