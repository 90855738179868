import PropTypes from 'prop-types';
// @mui
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

// components
import FormProvider, { RHFTextField } from '../hook-form';
// api
import { useAddTagMutation } from '../../features/apis/mCoderApi';
// hook
import { useErrorNotice, useSuccessNotice } from '../../hooks';

const FormSchema = Yup.object().shape({
  tagName: Yup.string()
    .required('Tag name is required')
    .min(4, 'Mininum 4 characters')
    .max(32, 'Maximum 32 characters'),
});

AddTagDialog.propTypes = {
  tagGroup: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export function AddTagDialog({ tagGroup, open, handleClose }) {
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      tagName: '',
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const noticeError = useErrorNotice();
  const noticeSuccess = useSuccessNotice();
  const [addTag] = useAddTagMutation();
  const onSubmit = (data) => {
    addTag({ tagGroupName: tagGroup.name, tagName: data.tagName })
      .unwrap()
      .then(() => {
        noticeSuccess(`New tag ${data.tagName} is added`);
        reset();
        handleClose();
      })
      .catch((error) => {
        noticeError(error);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add a tag</DialogTitle>
        <DialogContent>
          <RHFTextField
            autoFocus
            name="tagName"
            label="New Tag Name"
            sx={{ mt: 2, minWidth: 120 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" size="small">
            Cancel
          </Button>
          <LoadingButton fullWidth type="submit" variant="contained" size="small" loading={isSubmitting}>
            Add Tag
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
