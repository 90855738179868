import { configureStore } from '@reduxjs/toolkit';

import authReducer from '../features/auth/authSlice';
import caseQueryReducer, { queryReset } from '../features/cases/caseQuerySlice';
import caseCodingResultReducer, { codingResultReset } from '../features/cases/caseCodingResultSlice';
import supportingReasonsReducer from '../features/cases/supportingReasonsSlice';
import { mCoderApi } from '../features/apis/mCoderApi';

export default configureStore({
  reducer: {
    auth: authReducer,
    caseQuery: caseQueryReducer,
    caseCodingResult: caseCodingResultReducer,
    supportingReasons: supportingReasonsReducer,
    // Add the generated reducer as a specific top-level slice
    [mCoderApi.reducerPath]: mCoderApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mCoderApi.middleware),
});

export function resetAllStatesExceptAuth(dispatch) {
  // reset api state
  // https://stackoverflow.com/questions/71573317/how-to-invalidate-rtk-query-cachesreset-state-globally
  dispatch(mCoderApi.util.resetApiState());
  dispatch(queryReset());
  dispatch(codingResultReset());
}
