import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderSettingsApi = mCoderApi.injectEndpoints({
  endpoints: (builder) => ({
    getAttrValues: builder.query({
      query: ({ field }) => `/settings/attrs/${field}`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
    getSettings: builder.query({
      query: () => `/settings`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSettingsQuery,
  useGetAttrValuesQuery,
  useLazyGetAttrValuesQuery,
} = mCoderSettingsApi;
