import PropTypes from 'prop-types';
import { useTimer } from 'react-timer-hook';
import { useEffect } from 'react';
// @mui
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button
} from '@mui/material';

CountdownDialog.propTypes = {
  description: PropTypes.string,
  started: PropTypes.bool,
  countdownInMs: PropTypes.number,
  onExpire: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export function CountdownDialog({
  description,
  started = false,
  countdownInMs,
  onExpire: handleExpire = () => { },
  open,
  onClose: handleClose = () => { },
}) {
  const handleRequest = () => {
    handleClose();
    handleExpire();
  };

  // This is a required 
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 300);
  const { seconds, restart } = useTimer({
    expiryTimestamp,
    onExpire: handleRequest,
    autoStart: false
  });

  useEffect(() => {
    if (started) {
      // trigger 30 seconds to countdown
      const time = new Date();
      time.setSeconds(time.getSeconds() + countdownInMs);
      restart(time);
    }

  }, [started, countdownInMs, restart])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Force Logout</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        <Stack direction='row' alignItems='end' justifyContent='center'>
          <Typography variant='h1'>{seconds}</Typography>
          <Typography variant='body1'>seconds</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' size="small" endIcon={<LogoutTwoToneIcon />} onClick={handleRequest}>Logout Now</Button>
      </DialogActions>
    </Dialog>
  );
}
