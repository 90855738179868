import { createSelector, createSlice } from '@reduxjs/toolkit';
// // @mui
// import { alpha } from '@mui/material/styles';

// // https://dev.to/admitkard/auto-generate-avatar-colors-randomly-138j
// const hRange = [0, 360];
// const sRange = [50, 75];
// const lRange = [25, 60];

// const normalizeHash = (hash, min, max) => Math.floor(hash * (max - min) + min);

// const generateHSL = () => {
//   const hash = Math.floor(Math.random() * 20) / 20.0;
//   const h = normalizeHash(hash, hRange[0], hRange[1]);
//   const s = normalizeHash(hash, sRange[0], sRange[1]);
//   const l = normalizeHash(hash, lRange[0], lRange[1]);
//   return `hsl(${h}, ${s}%, ${l}%)`;
// };

const hsl = [
  'hsl(270, 49%, 51%)',
  'hsl(125, 58%, 37%)',
  'hsl(198, 63%, 71%)',
  'hsl(288, 70%, 53%)',
  'hsl(144, 60%, 39%)',
  'hsl(180, 62%, 63%)',
  'hsl(90, 37%, 33%)',
];

const generateHSL2 = (color) => hsl[color];
// We want to hover the reason in both primary doc and supporting reason area by the different background color.
// however, the primary doc may be very long out of the screen.
// When both are rendered in the different background color, you can see the part in the primary doc (due to out of screen)
// So we gradually change the background color in the primary doc. To give the user sometime to scroll down to find it.
const transactionSecs = 10;

const generateSX = (color) => ({
  //   bgcolor: alpha(generateHSL2(color), 0.5),
  //   color: '#000',
  //   fontWeight: 'bold',
  fontStyle: 'italic',
  transition: `background-color ${transactionSecs}s`,
});

const generateHoverSX = (color) => ({
  bgcolor: generateHSL2(color),
  color: '#fff',
  fontWeight: 'bold',
  fontStyle: 'italic',
});

const getSupportingReasonKey = (icdCode, i) => `${icdCode}_${i}`;

export const supportingReasonsSlice = createSlice({
  name: 'supportingReasons',
  initialState: {
    data: {},
  },
  reducers: {
    supportingReasonsReset: (draftState, action) => {
      let color = 0;

      // clean it
      draftState.data = {};

      const { diagnoses } = action.payload;
      diagnoses.forEach((diagnose, i) => {
        const {
          icd_code: { value: icdCode, supporting_reasons },
        } = diagnose;
        if (supporting_reasons && supporting_reasons.length > 0) {
          supporting_reasons.forEach((supportingReason, textIndex) => {
            draftState.data[getSupportingReasonKey(icdCode, textIndex)] = {
              icdCode,
              index: textIndex,
              sx: generateSX(color),
              color,
              ...supportingReason,
            };

            color += 1;
          });
        }
      });
    },
    supportingReasonsHovered: (draftState, action) => {
      const { icdCode, index, hovered } = action.payload;
      const text = draftState.data[getSupportingReasonKey(icdCode, index)];
      text.sx = hovered ? generateHoverSX(text.color) : generateSX(text.color);
    },
  },
});

export const { supportingReasonsReset, supportingReasonsHovered } = supportingReasonsSlice.actions;

export default supportingReasonsSlice.reducer;

const selectSupportingReasons = (state) => state.supportingReasons?.data ?? [];

export const makeGetSupportingReasonByICDCode = (icdCode, index) =>
  createSelector(selectSupportingReasons, (supportingReasons) => {
    const key = getSupportingReasonKey(icdCode, index);
    const supportingReason = Object.values(supportingReasons).find(
      (x) => key === getSupportingReasonKey(x.icdCode, x.index)
    );
    return supportingReason ?? {};
  });

// escape regular expression pattern
// please read: https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
function escapeRegExp(pattern) {
  return pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const breakContent = (content, { icdCode, index, value }) => {
  const regex = new RegExp(`(${escapeRegExp(value)})`, 'g');
  const segments = content.split(regex);
  return segments.map((seg) => (seg === value ? { icdCode, index, text: value } : { text: seg }));
};

export const makeGetAnalyzedContent = (content) =>
  createSelector(selectSupportingReasons, (supportingReasons) => {
    // sort by text length in desc order
    const sortedSupportingReasons = Object.values(supportingReasons).sort((a, b) => b.value.length - a.value.length);
    let segments = [{ text: content }];
    sortedSupportingReasons.forEach((supportingReason) => {
      segments = segments.map((seg) => (seg.icdCode ? [seg] : breakContent(seg.text, supportingReason))).flat(1);
    });
    return segments;
  });
