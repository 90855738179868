// i18n
import '../locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
// routes
import Router from '../routes';
// theme
import ThemeProvider from '../theme';
// locales
import ThemeLocalization from '../locales';
// components
import SnackbarProvider from '../components/snackbar';
import { ThemeSettings, SettingsProvider } from '../components/settings';
import { MotionLazyContainer } from '../components/animate';
import ScrollToTop from '../components/scroll-to-top';

// Check our docs
// https://docs.minimals.cc/authentication/js-version

// import { AuthProvider } from './auth/JwtContext';
import { AuthProvider } from '../auth/MsalContext';
// Sentry
import { SENTRY_SETTINGS, ENV, VER } from '../config-global';

// ----------------------------------------------------------------------

Sentry.init({
  dsn: SENTRY_SETTINGS.dsn,
  environment: ENV,
  release: VER,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: SENTRY_SETTINGS.tracePropagationTargets,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: SENTRY_SETTINGS.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: SENTRY_SETTINGS.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: SENTRY_SETTINGS.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsProvider>
            <BrowserRouter>
              <ScrollToTop />
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <Router />
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                </ThemeProvider>
              </MotionLazyContainer>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
