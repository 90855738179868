import { useMemo } from 'react';
// api
import { useGetSettingsQuery } from '../../apis/mCoderApi';
import {
  selectExactMatchOptions,
  selectTagFilterOptions,
  selectAttrFiltersOptions,
  selectDateFilterOptions,
  selectSortOptions,
} from '../settingsSelectors';

function useOptions(rawSelect) {
  // useMemo pattern
  // https://redux.js.org/usage/deriving-data-selectors#creating-unique-selector-instances
  const cachedSelect = useMemo(rawSelect, [rawSelect]);

  return useGetSettingsQuery(undefined, {
    selectFromResult: (result) => ({
      // We can optionally include the other metadata fields from the result here
      ...result,
      // Include a field called `user` in the hook result object,
      // which will be a specific user
      value: cachedSelect(result),
    }),
  });
}

export function useExactMatchOptions() {
  const getSettings = useOptions(selectExactMatchOptions);
  return [getSettings.value ?? [], getSettings];
}

export function useTagFilterOptions() {
  const getSettings = useOptions(selectTagFilterOptions);
  return [getSettings.value ?? [], getSettings];
}

export function useTagGroupFromTagFilterOptions(tagGroupName) {
  const [tagFilterOptions, getSettings] = useTagFilterOptions();
  return [tagFilterOptions.find((tagGroup) => tagGroup.name === tagGroupName), getSettings];
}

export function useAttrFiltersOptions() {
  const getSettings = useOptions(selectAttrFiltersOptions);
  return [getSettings.value ?? [], getSettings];
}

export function useDateFilterOptions() {
  const getSettings = useOptions(selectDateFilterOptions);
  return [getSettings.value ?? [], getSettings];
}

export function useSortOptions() {
  const getSettings = useOptions(selectSortOptions);
  return [getSettings.value ?? [], getSettings];
}
