import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputBase,
  Button,
  Stack,
  Alert,
  AlertTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hook
import { usePostCommentHandler } from '../../features/comments/hooks';

CommentConfirmationDialog.propTypes = {
  caseID: PropTypes.string,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node,
  color: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default function CommentConfirmationDialog({
  caseID,
  title,
  confirmButtonText,
  description,
  content,
  color,
  open,
  onClose: handleClose,
  onConfirm: handleConfirm = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [handlePostComment] = usePostCommentHandler(caseID, handleClose);

  const action = useCallback(() => {
    setLoading(true);
    try {
      // We should take the actual action before posting a comment
      handleConfirm();
      handlePostComment({ comment, comment_to: null });
    } finally {
      setLoading(false);
    }
  }, [setLoading, handleConfirm, handlePostComment, comment]);

  // const handleKeyDown = useCallback(
  //   (event) => {
  //     if (event.key === 'Enter') {
  //       action();
  //     }
  //   },
  //   [action]
  // );

  const handleClick = useCallback(
    (event) => {
      action();
    },
    [action]
  );

  const others = { variant: 'contained', size: 'small' };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        <Stack direction="column" spacing={2} sx={{ my: 2 }}>
          <InputBase
            fullWidth
            multiline
            minRows={5}
            maxRows={10}
            value={comment}
            placeholder="Write a comment…"
            // onKeyDown={handleKeyDown}
            onChange={(event) => setComment(event.target.value)}
            sx={{
              p: 1,
              borderRadius: 1,
              border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
            }}
          />
          {content && (
            <Alert severity="info">
              <AlertTitle>{content}</AlertTitle>
            </Alert>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="inherit" autoFocus {...others}>
          Cancel
        </Button>
        <LoadingButton loading={loading} onClick={handleClick} color={color} {...others}>
          {confirmButtonText ?? 'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
