import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderConfigsApi = mCoderApi.injectEndpoints({
  tagTypes: ['Configs'],
  endpoints: (builder) => ({
    getConfigs: builder.query({
      query: () => `/configs`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response,
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['Configs'],
    }),
    updateConfig: builder.mutation({
      query: ({ configKey, configValue }) => ({
        url: `/configs/${configKey}`,
        method: 'POST',
        body: {
          config_value: configValue,
        },
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['Configs'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetConfigsQuery, useUpdateConfigMutation } = mCoderConfigsApi;
