import { mCoderApi, customTransformErrorResponse } from './base';

const mCoderClientGroupsApi = mCoderApi.injectEndpoints({
  tagTypes: ['ClientGroups'],
  endpoints: (builder) => ({
    getClientGroups: builder.query({
      query: () => `/client_groups`,
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.client_groups ?? [],
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      providesTags: ['ClientGroups'],
    }),
    createClientGroup: builder.mutation({
      query: ({ name, description }) => ({
        url: `/client_groups`,
        method: 'POST',
        body: {
          name,
          description,
        },
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['ClientGroups'],
    }),
    deleteClientGroup: builder.mutation({
      query: ({ clientGroupID }) => ({
        url: `/client_groups/${clientGroupID}`,
        method: 'DELETE',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => ['ClientGroups'],
    }),
    setClientGroup: builder.mutation({
      query: ({ clientGroupID, clientID }) => ({
        url: `/client_groups/${clientGroupID}/clients/${clientID}`,
        method: 'POST',
      }),
      // Pick out errors and prevent nested properties in a hook or selector
      transformErrorResponse: customTransformErrorResponse,
      invalidatesTags: (result, error, arg) => [{ type: 'Clients' }, { type: 'ClientGroups' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetClientGroupsQuery,
  useCreateClientGroupMutation,
  useDeleteClientGroupMutation,
  useSetClientGroupMutation,
} = mCoderClientGroupsApi;
