import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
// @mui
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
  AlertTitle,
} from '@mui/material';

ConfirmationDialog.propTypes = {
  description: PropTypes.string,
  warningDescription: PropTypes.string,
  confirmButtonText: PropTypes.string,
  color: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default function ConfirmationDialog({
  description,
  warningDescription,
  confirmButtonText = 'Confirm',
  color = 'error',
  open,
  onClose,
  onConfirm,
}) {
  const focusButtonRef = useRef(null);

  // Effect to manually set focus when the dialog becomes visible
  useEffect(() => {
    if (open) {
      // This timeout ensures that focus is shifted after all rendering and transitions are complete
      const timer = setTimeout(() => {
        focusButtonRef.current?.focus();
      }, 300); // Adjust timing as needed based on your dialog's animation speed

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [open]);

  const handleCancel = () => onClose();
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmation</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        {warningDescription && (
          <Alert severity="warning" sx={{ mt: 1 }}>
            <AlertTitle>{warningDescription}</AlertTitle>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button ref={focusButtonRef} onClick={handleCancel} variant="contained" color="inherit" size="small" autoFocus>
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" color={color} size="small">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
