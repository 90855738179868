import PropTypes from 'prop-types';
// @mui
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// components
import FormProvider, { RHFTextField } from '../hook-form';
// api
import { useAddTagGroupMutation } from '../../features/apis/mCoderApi';
// hook
import { useErrorNotice, useSuccessNotice } from '../../hooks';

const FormSchema = Yup.object().shape({
  tagGroupName: Yup.string()
    .required('Tag group name is required')
    .min(4, 'Mininum 4 characters')
    .max(32, 'Maximum 32 characters'),
});

AddTagGroupDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export function AddTagGroupDialog({ open, handleClose }) {
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      tagGroupName: '',
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const noticeError = useErrorNotice();
  const noticeSuccess = useSuccessNotice();
  const [addTagGroup] = useAddTagGroupMutation();
  const onSubmit = (data) => {
    addTagGroup({ ...data })
      .unwrap()
      .then(() => {
        noticeSuccess(`New tag group ${data.tagGroupName} is added`);
        reset();
        handleClose();
      })
      .catch((error) => {
        noticeError(error);
      });

    reset();

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add a tag group</DialogTitle>
        <DialogContent>
          <RHFTextField
            autoFocus
            name="tagGroupName"
            label="New Tag Group Name"
            sx={{ mt: 2, minWidth: 120 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" size="small">
            Cancel
          </Button>
          <LoadingButton fullWidth type="submit" variant="contained" size="small" loading={isSubmitting}>
            Add Tag Group
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
